import Head from 'next/head';
import { CldOgImage } from 'next-cloudinary';
import { Fragment } from 'react';

import { SeoFragment } from '@/api';

interface Props {
  seo?: SeoFragment | null;
}

export const Seo = ({ seo }: Props) => {
  const defaults = {
    title: 'Rimac Group',
    description:
      'The Rimac Group brings together the most advanced hypercars in the world with a globally renowned team developing high-performance electrification technologies for the global OEMs.',
    image: 'https://www.rimac-group.com/media/aepmos34/group-share.jpg',
  };

  return (
    <>
      <Head>
        <title>{seo?.metaTitle || defaults?.title}</title>
        <meta
          name="description"
          content={seo?.metaDescription || defaults?.description}
        />
        <meta itemProp="name" content={seo?.metaTitle || defaults?.title} />

        {seo?.keywords && <meta name="keywords" content={seo?.keywords} />}
        {seo?.metaRobots && (
          <meta
            name="robots"
            content={
              process.env.NODE_ENV === 'production'
                ? seo?.metaRobots
                : 'noindex, nofollow'
            }
          />
        )}

        {seo?.metaSocial?.map((social, index) => {
          const tag = social?.socialNetwork === 'Facebook' ? 'og' : 'twitter';
          return (
            <Fragment key={`seoSocial-${index}`}>
              <meta property={`${tag}:title`} content={social?.title} />
              <meta
                property={`${tag}:description`}
                content={social?.description}
              />
            </Fragment>
          );
        })}
        {seo?.metaViewport && (
          <meta name="viewport" content={seo?.metaViewport} />
        )}
        {seo?.canonicalURL && <link rel="canonical" href={seo?.canonicalURL} />}
        {seo?.structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(seo?.structuredData)}
          </script>
        )}
      </Head>
      <CldOgImage
        src={seo?.metaImage?.data?.attributes?.url || defaults?.image}
        alt={seo?.metaTitle || defaults?.title}
      />
    </>
  );
};
