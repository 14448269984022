import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CookiePolicyCookiePolicyDynamicZoneInput: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  LegalNoticeLegalNoticeDynamicZoneInput: { input: any; output: any };
  PrivacyPolicyPoliciesDynamicZoneInput: { input: any; output: any };
  Upload: { input: any; output: any };
  WhistleblowingPolicyPoliciesDynamicZoneInput: { input: any; output: any };
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Career = {
  __typename?: 'Career';
  animatedSelect?: Maybe<Array<Maybe<ComponentUiCampus>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hero: ComponentUiHeroText;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CareerAnimatedSelectArgs = {
  filters?: InputMaybe<ComponentUiCampusFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CareerEntity = {
  __typename?: 'CareerEntity';
  attributes?: Maybe<Career>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CareerEntityResponse = {
  __typename?: 'CareerEntityResponse';
  data?: Maybe<CareerEntity>;
};

export type CareerInput = {
  animatedSelect?: InputMaybe<Array<InputMaybe<ComponentUiCampusInput>>>;
  hero?: InputMaybe<ComponentUiHeroTextInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Company = {
  __typename?: 'Company';
  animatedSelect?: Maybe<Array<Maybe<ComponentUiCampus>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hero?: Maybe<ComponentUiHeroText>;
  productImageBlock?: Maybe<Array<Maybe<ComponentSharedProductImageBlock>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyAnimatedSelectArgs = {
  filters?: InputMaybe<ComponentUiCampusFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyProductImageBlockArgs = {
  filters?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  attributes?: Maybe<Company>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse';
  data?: Maybe<CompanyEntity>;
};

export type CompanyInput = {
  animatedSelect?: InputMaybe<Array<InputMaybe<ComponentUiCampusInput>>>;
  hero?: InputMaybe<ComponentUiHeroTextInput>;
  productImageBlock?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterColumn = {
  __typename?: 'ComponentFooterColumn';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentFooterLink>>>;
};

export type ComponentFooterColumnLinkArgs = {
  filters?: InputMaybe<ComponentFooterLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterColumnFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentFooterLinkFiltersInput>;
  not?: InputMaybe<ComponentFooterColumnFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
};

export type ComponentFooterColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentFooterLinkInput>>>;
};

export type ComponentFooterContact = {
  __typename?: 'ComponentFooterContact';
  address?: Maybe<ComponentFooterLink>;
  email?: Maybe<ComponentFooterLink>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterContactInput = {
  address?: InputMaybe<ComponentFooterLinkInput>;
  email?: InputMaybe<ComponentFooterLinkInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterLink = {
  __typename?: 'ComponentFooterLink';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentFooterLinkFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentFooterLinkInput = {
  file?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterPolicies = {
  __typename?: 'ComponentFooterPolicies';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentFooterLink>>>;
};

export type ComponentFooterPoliciesLinkArgs = {
  filters?: InputMaybe<ComponentFooterLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterPoliciesInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentFooterLinkInput>>>;
};

export type ComponentFooterSocials = {
  __typename?: 'ComponentFooterSocials';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterSocialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationContactInfo = {
  __typename?: 'ComponentNavigationContactInfo';
  address?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationContactInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationLink = {
  __typename?: 'ComponentNavigationLink';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
};

export type ComponentNavigationLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationOpenMenuColumn = {
  __typename?: 'ComponentNavigationOpenMenuColumn';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentNavigationLink>>>;
  socials?: Maybe<ComponentNavigationSocials>;
};

export type ComponentNavigationOpenMenuColumnLinkArgs = {
  filters?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentNavigationOpenMenuColumnFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>>
  >;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  not?: InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>>
  >;
  socials?: InputMaybe<ComponentNavigationSocialsFiltersInput>;
};

export type ComponentNavigationOpenMenuColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkInput>>>;
  socials?: InputMaybe<ComponentNavigationSocialsInput>;
};

export type ComponentNavigationSocials = {
  __typename?: 'ComponentNavigationSocials';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  linkedIn?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationSocialsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationSocialsFiltersInput>>>;
  instagram?: InputMaybe<StringFilterInput>;
  linkedIn?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationSocialsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationSocialsFiltersInput>>>;
  tiktok?: InputMaybe<StringFilterInput>;
  youtube?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationSocialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesBullets = {
  __typename?: 'ComponentPoliciesBullets';
  bullet?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesCookiesTable = {
  __typename?: 'ComponentPoliciesCookiesTable';
  id: Scalars['ID']['output'];
  richText?: Maybe<Scalars['JSON']['output']>;
  tableHead?: Maybe<Array<Maybe<ComponentPoliciesTableHeading>>>;
  tableRow?: Maybe<ComponentPoliciesTabelRow>;
};

export type ComponentPoliciesCookiesTableTableHeadArgs = {
  filters?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesCookiesTableInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  richText?: InputMaybe<Scalars['JSON']['input']>;
  tableHead?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingInput>>>;
  tableRow?: InputMaybe<ComponentPoliciesTabelRowInput>;
};

export type ComponentPoliciesDevider = {
  __typename?: 'ComponentPoliciesDevider';
  devider?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesDownloadLink = {
  __typename?: 'ComponentPoliciesDownloadLink';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesMainHeading = {
  __typename?: 'ComponentPoliciesMainHeading';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentPoliciesParagpraph = {
  __typename?: 'ComponentPoliciesParagpraph';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItem = {
  __typename?: 'ComponentPoliciesRowItem';
  id: Scalars['ID']['output'];
  item?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
  item?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
};

export type ComponentPoliciesRowItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesTabelRow = {
  __typename?: 'ComponentPoliciesTabelRow';
  id: Scalars['ID']['output'];
  table_rows?: Maybe<TableRowRelationResponseCollection>;
};

export type ComponentPoliciesTabelRowTable_RowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTabelRowInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  table_rows?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ComponentPoliciesTableHeading = {
  __typename?: 'ComponentPoliciesTableHeading';
  headingItem?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesTableHeadingFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>
  >;
  headingItem?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>>;
};

export type ComponentPoliciesTableHeadingInput = {
  headingItem?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentPoliciesTableRows = {
  __typename?: 'ComponentPoliciesTableRows';
  id: Scalars['ID']['output'];
  rowItem?: Maybe<Array<Maybe<ComponentPoliciesRowItem>>>;
};

export type ComponentPoliciesTableRowsRowItemArgs = {
  filters?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableTextContent = {
  __typename?: 'ComponentPoliciesTableTextContent';
  id: Scalars['ID']['output'];
  richText?: Maybe<Scalars['JSON']['output']>;
  tableHead?: Maybe<Array<Maybe<ComponentPoliciesTableHeading>>>;
  tableRow?: Maybe<ComponentPoliciesTabelRow>;
};

export type ComponentPoliciesTableTextContentTableHeadArgs = {
  filters?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTitleTextContent = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedDropItem = {
  __typename?: 'ComponentSharedDropItem';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedDropItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedDropItemFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedDropItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedDropItemFiltersInput>>>;
};

export type ComponentSharedDropItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedHeadlineWithImageSection = {
  __typename?: 'ComponentSharedHeadlineWithImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  mobileTable?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  mobileTableTitle?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedHeadlineWithImageSectionMobileTableArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedHeadlineWithImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  mobileTable?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  mobileTableTitle?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedHeroVideoSection = {
  __typename?: 'ComponentSharedHeroVideoSection';
  button?: Maybe<ComponentUiButton>;
  heroVideo?: Maybe<ComponentUiVideo>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedLargeImageSection = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedLargeImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedMapSection = {
  __typename?: 'ComponentSharedMapSection';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mapItems?: Maybe<Array<Maybe<ComponentUiMapItem>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedMapSectionMapItemsArgs = {
  filters?: InputMaybe<ComponentUiMapItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedMapSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mapItems?: InputMaybe<Array<InputMaybe<ComponentUiMapItemInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String']['output'];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedNewsroom = {
  __typename?: 'ComponentSharedNewsroom';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  cards?: Maybe<Array<Maybe<ComponentUiNewsroomCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedNewsroomCardsArgs = {
  filters?: InputMaybe<ComponentUiNewsroomCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedNewsroomInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  cards?: InputMaybe<Array<InputMaybe<ComponentUiNewsroomCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedProductImageBlock = {
  __typename?: 'ComponentSharedProductImageBlock';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  extraText?: Maybe<Scalars['String']['output']>;
  galleryExtraText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  largeSpec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  paragraph?: Maybe<Scalars['String']['output']>;
  paragraphTwo?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedProductImageBlockLargeSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductImageBlockFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockFiltersInput>>
  >;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  extraText?: InputMaybe<StringFilterInput>;
  galleryExtraText?: InputMaybe<StringFilterInput>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  largeSpec?: InputMaybe<ComponentUiSpecFiltersInput>;
  not?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockFiltersInput>>
  >;
  paragraph?: InputMaybe<StringFilterInput>;
  paragraphTwo?: InputMaybe<StringFilterInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedProductImageBlockInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  extraText?: InputMaybe<Scalars['String']['input']>;
  galleryExtraText?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  largeSpec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraphTwo?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  metaDescription: Scalars['String']['output'];
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars['String']['output'];
  metaViewport?: Maybe<Scalars['String']['output']>;
  structuredData?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaRobots?: InputMaybe<Scalars['String']['input']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  metaViewport?: InputMaybe<Scalars['String']['input']>;
  structuredData?: InputMaybe<Scalars['JSON']['input']>;
};

export type ComponentUiArticle = {
  __typename?: 'ComponentUiArticle';
  alt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  link_path: Scalars['String']['output'];
  tag: Scalars['String']['output'];
  tagColor?: Maybe<Enum_Componentuiarticle_Tagcolor>;
  title: Scalars['String']['output'];
};

export type ComponentUiButton = {
  __typename?: 'ComponentUiButton';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
};

export type ComponentUiButtonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiButtonFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentUiButtonInput = {
  file?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiCampus = {
  __typename?: 'ComponentUiCampus';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiCampusFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiCampusFiltersInput>>>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentUiCampusFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCampusFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiCampusInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiCard = {
  __typename?: 'ComponentUiCard';
  button?: Maybe<Scalars['String']['output']>;
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiCardFiltersInput>>>;
  button?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCardFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiCardInput = {
  button?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiCardsSection = {
  __typename?: 'ComponentUiCardsSection';
  anchor?: Maybe<Scalars['String']['output']>;
  card?: Maybe<Array<Maybe<ComponentUiCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiCardsSectionCardArgs = {
  filters?: InputMaybe<ComponentUiCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiCardsSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  card?: InputMaybe<Array<InputMaybe<ComponentUiCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiContent = {
  __typename?: 'ComponentUiContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiContentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiContentFiltersInput>>>;
  not?: InputMaybe<ComponentUiContentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiContentFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentUiFacilitiesSection = {
  __typename?: 'ComponentUiFacilitiesSection';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  map?: Maybe<Array<Maybe<ComponentUiMap>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiFacilitiesSectionMapArgs = {
  filters?: InputMaybe<ComponentUiMapFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiGovernanceDiagram = {
  __typename?: 'ComponentUiGovernanceDiagram';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  mobileDropDown?: Maybe<Array<Maybe<ComponentUiGovernanceSection>>>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiGovernanceDiagramMobileDropDownArgs = {
  filters?: InputMaybe<ComponentUiGovernanceSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiGovernanceDiagramInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  mobileDropDown?: InputMaybe<
    Array<InputMaybe<ComponentUiGovernanceSectionInput>>
  >;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiGovernanceSection = {
  __typename?: 'ComponentUiGovernanceSection';
  drop_down_item?: Maybe<DropDownItemEntityResponse>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiGovernanceSectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiGovernanceSectionFiltersInput>>>;
  drop_down_item?: InputMaybe<DropDownItemFiltersInput>;
  not?: InputMaybe<ComponentUiGovernanceSectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiGovernanceSectionFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiGovernanceSectionInput = {
  drop_down_item?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeadline = {
  __typename?: 'ComponentUiHeadline';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link_path?: Maybe<Scalars['String']['output']>;
  link_text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHero = {
  __typename?: 'ComponentUiHero';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title: Scalars['String']['output'];
};

export type ComponentUiHeroInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeroText = {
  __typename?: 'ComponentUiHeroText';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHeroTextInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHistory = {
  __typename?: 'ComponentUiHistory';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item?: Maybe<Array<Maybe<ComponentUiHistoryItem>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHistoryItemArgs = {
  filters?: InputMaybe<ComponentUiHistoryItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiHistoryInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  item?: InputMaybe<Array<InputMaybe<ComponentUiHistoryItemInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHistoryItem = {
  __typename?: 'ComponentUiHistoryItem';
  alt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  linkPath?: Maybe<Scalars['String']['output']>;
  linkText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHistoryItemFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiHistoryItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  linkPath?: InputMaybe<StringFilterInput>;
  linkText?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiHistoryItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiHistoryItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  year?: InputMaybe<StringFilterInput>;
};

export type ComponentUiHistoryItemInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  linkPath?: InputMaybe<Scalars['String']['input']>;
  linkText?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiIcon = {
  __typename?: 'ComponentUiIcon';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  svg: UploadFileEntityResponse;
};

export type ComponentUiIconCard = {
  __typename?: 'ComponentUiIconCard';
  icon_svg?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiIconCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  not?: InputMaybe<ComponentUiIconCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiIconCardInput = {
  icon_svg?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiIconFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiIconFiltersInput>>>;
  not?: InputMaybe<ComponentUiIconFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiIconFiltersInput>>>;
};

export type ComponentUiIconInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  svg?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiImage = {
  __typename?: 'ComponentUiImage';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiImageFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
  not?: InputMaybe<ComponentUiImageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
};

export type ComponentUiImageInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiInvestor = {
  __typename?: 'ComponentUiInvestor';
  bgColor?: Maybe<Enum_Componentuiinvestor_Bgcolor>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type ComponentUiInvestorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiInvestorFiltersInput>>>;
  bgColor?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiInvestorFiltersInput>;
  note?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiInvestorFiltersInput>>>;
  percentage?: InputMaybe<FloatFilterInput>;
};

export type ComponentUiInvestorInput = {
  bgColor?: InputMaybe<Enum_Componentuiinvestor_Bgcolor>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type ComponentUiInvestors = {
  __typename?: 'ComponentUiInvestors';
  icon?: Maybe<Array<Maybe<ComponentUiIcon>>>;
  id: Scalars['ID']['output'];
  investor?: Maybe<Array<Maybe<ComponentUiInvestor>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiInvestorsIconArgs = {
  filters?: InputMaybe<ComponentUiIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiInvestorsInvestorArgs = {
  filters?: InputMaybe<ComponentUiInvestorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiInvestorsSection = {
  __typename?: 'ComponentUiInvestorsSection';
  anchor?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Array<Maybe<ComponentUiIcon>>>;
  id: Scalars['ID']['output'];
  investor?: Maybe<Array<Maybe<ComponentUiInvestor>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiInvestorsSectionIconArgs = {
  filters?: InputMaybe<ComponentUiIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiInvestorsSectionInvestorArgs = {
  filters?: InputMaybe<ComponentUiInvestorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiInvestorsSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Array<InputMaybe<ComponentUiIconInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  investor?: InputMaybe<Array<InputMaybe<ComponentUiInvestorInput>>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiList = {
  __typename?: 'ComponentUiList';
  id: Scalars['ID']['output'];
  indent?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiListFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiListFiltersInput>>>;
  indent?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentUiListFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiListFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentUiLogo = {
  __typename?: 'ComponentUiLogo';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  svg?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiMap = {
  __typename?: 'ComponentUiMap';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiMapFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiMapFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiMapFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiMapFiltersInput>>>;
};

export type ComponentUiMapItem = {
  __typename?: 'ComponentUiMapItem';
  blackText?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  leftMapImage: UploadFileEntityResponse;
  leftMapImageMobile?: Maybe<UploadFileEntityResponse>;
  leftMapTitle: Scalars['String']['output'];
  rightMapImage: UploadFileEntityResponse;
  rightMapImageMobile?: Maybe<UploadFileEntityResponse>;
  rightMapTitle: Scalars['String']['output'];
  tabTitle: Scalars['String']['output'];
  tagColor: Enum_Componentuimapitem_Tagcolor;
};

export type ComponentUiMapItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiMapItemFiltersInput>>>;
  blackText?: InputMaybe<BooleanFilterInput>;
  leftMapTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiMapItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiMapItemFiltersInput>>>;
  rightMapTitle?: InputMaybe<StringFilterInput>;
  tabTitle?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
};

export type ComponentUiMapItemInput = {
  blackText?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  leftMapImage?: InputMaybe<Scalars['ID']['input']>;
  leftMapImageMobile?: InputMaybe<Scalars['ID']['input']>;
  leftMapTitle?: InputMaybe<Scalars['String']['input']>;
  rightMapImage?: InputMaybe<Scalars['ID']['input']>;
  rightMapImageMobile?: InputMaybe<Scalars['ID']['input']>;
  rightMapTitle?: InputMaybe<Scalars['String']['input']>;
  tabTitle?: InputMaybe<Scalars['String']['input']>;
  tagColor?: InputMaybe<Enum_Componentuimapitem_Tagcolor>;
};

export type ComponentUiMiniature = {
  __typename?: 'ComponentUiMiniature';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  miniature?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiNewsroomCard = {
  __typename?: 'ComponentUiNewsroomCard';
  id: Scalars['ID']['output'];
  image: ComponentUiImage;
  link: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiNewsroomCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiNewsroomCardFiltersInput>>>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  link?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiNewsroomCardFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiNewsroomCardFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiNewsroomCardInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  link?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiParagraph = {
  __typename?: 'ComponentUiParagraph';
  id: Scalars['ID']['output'];
  paragraphs?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiPolicySection = {
  __typename?: 'ComponentUiPolicySection';
  content?: Maybe<Array<Maybe<ComponentUiContent>>>;
  cookiesName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  list?: Maybe<Array<Maybe<ComponentUiList>>>;
  table?: Maybe<ComponentUiTable>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiPolicySectionContentArgs = {
  filters?: InputMaybe<ComponentUiContentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiPolicySectionListArgs = {
  filters?: InputMaybe<ComponentUiListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiProductImage = {
  __typename?: 'ComponentUiProductImage';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  imagePosition: Enum_Componentuiproductimage_Imageposition;
  paragraph?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiSpec = {
  __typename?: 'ComponentUiSpec';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiSpecFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiSpecFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiSpecFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiSpecFiltersInput>>>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentUiSpecInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTable = {
  __typename?: 'ComponentUiTable';
  head?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rows?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTeam = {
  __typename?: 'ComponentUiTeam';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  teammates?: Maybe<Array<Maybe<ComponentUiTeamMate>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTeamTeammatesArgs = {
  filters?: InputMaybe<ComponentUiTeamMateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiTeamInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  teammates?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTeamMate = {
  __typename?: 'ComponentUiTeamMate';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  logos?: Maybe<UploadFileRelationResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  secondPosition?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTeamMateLogosArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiTeamMateFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiTeamMateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateFiltersInput>>>;
  position?: InputMaybe<StringFilterInput>;
  secondPosition?: InputMaybe<StringFilterInput>;
};

export type ComponentUiTeamMateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  logos?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  secondPosition?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTextBlock = {
  __typename?: 'ComponentUiTextBlock';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  paragraph?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  textSection?: Maybe<Array<Maybe<ComponentUiTextSection>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTextBlockTextSectionArgs = {
  filters?: InputMaybe<ComponentUiTextSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiTextBlockInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  textSection?: InputMaybe<Array<InputMaybe<ComponentUiTextSectionInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTextSection = {
  __typename?: 'ComponentUiTextSection';
  icon_svg?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTextSectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiTextSectionFiltersInput>>>;
  not?: InputMaybe<ComponentUiTextSectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiTextSectionFiltersInput>>>;
  text?: InputMaybe<JsonFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiTextSectionInput = {
  icon_svg?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiValues = {
  __typename?: 'ComponentUiValues';
  anchor?: Maybe<Scalars['String']['output']>;
  iconCard?: Maybe<Array<Maybe<ComponentUiIconCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiValuesIconCardArgs = {
  filters?: InputMaybe<ComponentUiIconCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiValuesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  iconCard?: InputMaybe<Array<InputMaybe<ComponentUiIconCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiVideo = {
  __typename?: 'ComponentUiVideo';
  autoplay?: Maybe<Scalars['Boolean']['output']>;
  controls?: Maybe<Scalars['Boolean']['output']>;
  fullscreen?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  loop?: Maybe<Scalars['Boolean']['output']>;
  mobilePoster?: Maybe<UploadFileEntityResponse>;
  muted?: Maybe<Scalars['Boolean']['output']>;
  pauseVideo?: Maybe<Scalars['Boolean']['output']>;
  poster?: Maybe<UploadFileEntityResponse>;
  poster_alt?: Maybe<Scalars['String']['output']>;
  video?: Maybe<UploadFileEntityResponse>;
};

export type ContactFomResponse = {
  __typename?: 'ContactFomResponse';
  error?: Maybe<ErrorResult>;
  id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactFormDataInput = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  industry: Scalars['String']['input'];
  job: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  terms: Scalars['Boolean']['input'];
};

export type CookiePolicy = {
  __typename?: 'CookiePolicy';
  cookiePolicy?: Maybe<Array<Maybe<CookiePolicyCookiePolicyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiePolicyCookiePolicyDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type CookiePolicyEntity = {
  __typename?: 'CookiePolicyEntity';
  attributes?: Maybe<CookiePolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiePolicyEntityResponse = {
  __typename?: 'CookiePolicyEntityResponse';
  data?: Maybe<CookiePolicyEntity>;
};

export type CookiePolicyInput = {
  cookiePolicy?: InputMaybe<
    Array<Scalars['CookiePolicyCookiePolicyDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CookieSetting = {
  __typename?: 'CookieSetting';
  actionButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  necessaryCookiesHeading?: Maybe<Scalars['String']['output']>;
  necessaryCookiesTable?: Maybe<ComponentPoliciesCookiesTable>;
  optionalCookiesHeading?: Maybe<Scalars['String']['output']>;
  optionalCookiesTale?: Maybe<ComponentPoliciesCookiesTable>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  redirectButton?: Maybe<ComponentUiButton>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookieSettingEntity = {
  __typename?: 'CookieSettingEntity';
  attributes?: Maybe<CookieSetting>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookieSettingEntityResponse = {
  __typename?: 'CookieSettingEntityResponse';
  data?: Maybe<CookieSettingEntity>;
};

export type CookieSettingInput = {
  actionButtonLabel?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesTable?: InputMaybe<ComponentPoliciesCookiesTableInput>;
  optionalCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  optionalCookiesTale?: InputMaybe<ComponentPoliciesCookiesTableInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  redirectButton?: InputMaybe<ComponentUiButtonInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CookiesBanner = {
  __typename?: 'CookiesBanner';
  acceptButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  declineButtonLabel?: Maybe<Scalars['String']['output']>;
  editButtonLabel?: Maybe<Scalars['String']['output']>;
  extraLinks?: Maybe<Array<Maybe<ComponentUiButton>>>;
  heading?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiesBannerExtraLinksArgs = {
  filters?: InputMaybe<ComponentUiButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CookiesBannerEntity = {
  __typename?: 'CookiesBannerEntity';
  attributes?: Maybe<CookiesBanner>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiesBannerEntityResponse = {
  __typename?: 'CookiesBannerEntityResponse';
  data?: Maybe<CookiesBannerEntity>;
};

export type CookiesBannerInput = {
  acceptButtonLabel?: InputMaybe<Scalars['String']['input']>;
  declineButtonLabel?: InputMaybe<Scalars['String']['input']>;
  editButtonLabel?: InputMaybe<Scalars['String']['input']>;
  extraLinks?: InputMaybe<Array<InputMaybe<ComponentUiButtonInput>>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DropDownItem = {
  __typename?: 'DropDownItem';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  item?: Maybe<Array<Maybe<ComponentSharedDropItem>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DropDownItemItemArgs = {
  filters?: InputMaybe<ComponentSharedDropItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DropDownItemEntity = {
  __typename?: 'DropDownItemEntity';
  attributes?: Maybe<DropDownItem>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DropDownItemEntityResponse = {
  __typename?: 'DropDownItemEntityResponse';
  data?: Maybe<DropDownItemEntity>;
};

export type DropDownItemEntityResponseCollection = {
  __typename?: 'DropDownItemEntityResponseCollection';
  data: Array<DropDownItemEntity>;
  meta: ResponseCollectionMeta;
};

export type DropDownItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<DropDownItemFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  item?: InputMaybe<ComponentSharedDropItemFiltersInput>;
  not?: InputMaybe<DropDownItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<DropDownItemFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type DropDownItemInput = {
  item?: InputMaybe<Array<InputMaybe<ComponentSharedDropItemInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export enum Enum_Componentuiarticle_Tagcolor {
  Dark = 'Dark',
  Light = 'Light',
  Default = 'default',
}

export enum Enum_Componentuiinvestor_Bgcolor {
  CharcoalNavy_484F5E = 'charcoal_navy_484F5E',
  MistyTaupeC1Bab6 = 'misty_taupe_C1BAB6',
  SlateBlue_808897 = 'slate_blue_808897',
  SmokyIndigo_666E7D = 'smoky_indigo_666E7D',
}

export enum Enum_Componentuimapitem_Tagcolor {
  BugattiRedFf585A = 'bugatti_red_FF585A',
  RimacBrownC1Bab6 = 'rimac_brown_C1BAB6',
  TechnologyBlue_0A86Fa = 'technology_blue_0A86FA',
  VerneBlack_2D323C = 'verne_black_2D323C',
}

export enum Enum_Componentuiproductimage_Imageposition {
  Left = 'left',
  Right = 'right',
}

export enum Enum_Sitemapsitemap_Type {
  DefaultHreflang = 'default_hreflang',
  Index = 'index',
}

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorResult = {
  __typename?: 'ErrorResult';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type Footer = {
  __typename?: 'Footer';
  column?: Maybe<Array<Maybe<ComponentFooterColumn>>>;
  contact?: Maybe<ComponentFooterContact>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<ComponentFooterPolicies>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  socials?: Maybe<ComponentFooterSocials>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FooterColumnArgs = {
  filters?: InputMaybe<ComponentFooterColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FooterEntity = {
  __typename?: 'FooterEntity';
  attributes?: Maybe<Footer>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FooterEntityResponse = {
  __typename?: 'FooterEntityResponse';
  data?: Maybe<FooterEntity>;
};

export type FooterInput = {
  column?: InputMaybe<Array<InputMaybe<ComponentFooterColumnInput>>>;
  contact?: InputMaybe<ComponentFooterContactInput>;
  policies?: InputMaybe<ComponentFooterPoliciesInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  socials?: InputMaybe<ComponentFooterSocialsInput>;
};

export type GenericMorph =
  | Career
  | Company
  | ComponentFooterColumn
  | ComponentFooterContact
  | ComponentFooterLink
  | ComponentFooterPolicies
  | ComponentFooterSocials
  | ComponentNavigationContactInfo
  | ComponentNavigationLink
  | ComponentNavigationOpenMenuColumn
  | ComponentNavigationSocials
  | ComponentPoliciesBullets
  | ComponentPoliciesCookiesTable
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesParagpraph
  | ComponentPoliciesRowItem
  | ComponentPoliciesTabelRow
  | ComponentPoliciesTableHeading
  | ComponentPoliciesTableRows
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | ComponentSharedDropItem
  | ComponentSharedHeadlineWithImageSection
  | ComponentSharedHeroVideoSection
  | ComponentSharedLargeImageSection
  | ComponentSharedMapSection
  | ComponentSharedMetaSocial
  | ComponentSharedNewsroom
  | ComponentSharedProductImageBlock
  | ComponentSharedSeo
  | ComponentUiArticle
  | ComponentUiButton
  | ComponentUiCampus
  | ComponentUiCard
  | ComponentUiCardsSection
  | ComponentUiContent
  | ComponentUiFacilitiesSection
  | ComponentUiGovernanceDiagram
  | ComponentUiGovernanceSection
  | ComponentUiHeadline
  | ComponentUiHero
  | ComponentUiHeroText
  | ComponentUiHistory
  | ComponentUiHistoryItem
  | ComponentUiIcon
  | ComponentUiIconCard
  | ComponentUiImage
  | ComponentUiInvestor
  | ComponentUiInvestors
  | ComponentUiInvestorsSection
  | ComponentUiList
  | ComponentUiLogo
  | ComponentUiMap
  | ComponentUiMapItem
  | ComponentUiMiniature
  | ComponentUiNewsroomCard
  | ComponentUiParagraph
  | ComponentUiPolicySection
  | ComponentUiProductImage
  | ComponentUiSpec
  | ComponentUiTable
  | ComponentUiTeam
  | ComponentUiTeamMate
  | ComponentUiTextBlock
  | ComponentUiTextSection
  | ComponentUiValues
  | ComponentUiVideo
  | CookiePolicy
  | CookieSetting
  | CookiesBanner
  | DropDownItem
  | Footer
  | Governance
  | Home
  | I18NLocale
  | Impressum
  | LegalNotice
  | Navigation
  | PrivacyPolicy
  | SitemapSitemap
  | SitemapSitemapCache
  | TableRow
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | WhistleblowingPolicy;

export type Governance = {
  __typename?: 'Governance';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  downloadSection?: Maybe<ComponentUiCardsSection>;
  governanceSection?: Maybe<ComponentUiGovernanceDiagram>;
  hero?: Maybe<ComponentUiHero>;
  investorsSection?: Maybe<ComponentUiInvestorsSection>;
  productImageBlock?: Maybe<ComponentSharedProductImageBlock>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  team?: Maybe<ComponentUiTeam>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GovernanceEntity = {
  __typename?: 'GovernanceEntity';
  attributes?: Maybe<Governance>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GovernanceEntityResponse = {
  __typename?: 'GovernanceEntityResponse';
  data?: Maybe<GovernanceEntity>;
};

export type GovernanceInput = {
  downloadSection?: InputMaybe<ComponentUiCardsSectionInput>;
  governanceSection?: InputMaybe<ComponentUiGovernanceDiagramInput>;
  hero?: InputMaybe<ComponentUiHeroInput>;
  investorsSection?: InputMaybe<ComponentUiInvestorsSectionInput>;
  productImageBlock?: InputMaybe<ComponentSharedProductImageBlockInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<ComponentUiTeamInput>;
};

export type Home = {
  __typename?: 'Home';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  headlineWithImageSection?: Maybe<ComponentSharedHeadlineWithImageSection>;
  hero?: Maybe<ComponentUiHero>;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  mapSection?: Maybe<ComponentSharedMapSection>;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  sliderImageSection?: Maybe<ComponentUiHistory>;
  textBlock?: Maybe<ComponentUiTextBlock>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  values?: Maybe<ComponentUiValues>;
};

export type HomeEntity = {
  __typename?: 'HomeEntity';
  attributes?: Maybe<Home>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type HomeEntityResponse = {
  __typename?: 'HomeEntityResponse';
  data?: Maybe<HomeEntity>;
};

export type HomeInput = {
  headlineWithImageSection?: InputMaybe<ComponentSharedHeadlineWithImageSectionInput>;
  hero?: InputMaybe<ComponentUiHeroInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  mapSection?: InputMaybe<ComponentSharedMapSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  sliderImageSection?: InputMaybe<ComponentUiHistoryInput>;
  textBlock?: InputMaybe<ComponentUiTextBlockInput>;
  values?: InputMaybe<ComponentUiValuesInput>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type Impressum = {
  __typename?: 'Impressum';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdatedText?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImpressumEntity = {
  __typename?: 'ImpressumEntity';
  attributes?: Maybe<Impressum>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ImpressumEntityResponse = {
  __typename?: 'ImpressumEntityResponse';
  data?: Maybe<ImpressumEntity>;
};

export type ImpressumInput = {
  lastUpdatedText?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type LegalNotice = {
  __typename?: 'LegalNotice';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  legalNotice?: Maybe<Array<Maybe<LegalNoticeLegalNoticeDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LegalNoticeEntity = {
  __typename?: 'LegalNoticeEntity';
  attributes?: Maybe<LegalNotice>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type LegalNoticeEntityResponse = {
  __typename?: 'LegalNoticeEntityResponse';
  data?: Maybe<LegalNoticeEntity>;
};

export type LegalNoticeInput = {
  legalNotice?: InputMaybe<
    Array<Scalars['LegalNoticeLegalNoticeDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type LegalNoticeLegalNoticeDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createDropDownItem?: Maybe<DropDownItemEntityResponse>;
  createSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  createSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  createTableRow?: Maybe<TableRowEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteCareer?: Maybe<CareerEntityResponse>;
  deleteCompany?: Maybe<CompanyEntityResponse>;
  deleteCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  deleteCookieSetting?: Maybe<CookieSettingEntityResponse>;
  deleteCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  deleteDropDownItem?: Maybe<DropDownItemEntityResponse>;
  deleteFooter?: Maybe<FooterEntityResponse>;
  deleteGovernance?: Maybe<GovernanceEntityResponse>;
  deleteHome?: Maybe<HomeEntityResponse>;
  deleteImpressum?: Maybe<ImpressumEntityResponse>;
  deleteLegalNotice?: Maybe<LegalNoticeEntityResponse>;
  deleteNavigation?: Maybe<NavigationEntityResponse>;
  deletePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  deleteSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  deleteSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  deleteTableRow?: Maybe<TableRowEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  sendContactForm?: Maybe<ContactFomResponse>;
  updateCareer?: Maybe<CareerEntityResponse>;
  updateCompany?: Maybe<CompanyEntityResponse>;
  updateCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  updateCookieSetting?: Maybe<CookieSettingEntityResponse>;
  updateCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  updateDropDownItem?: Maybe<DropDownItemEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateFooter?: Maybe<FooterEntityResponse>;
  updateGovernance?: Maybe<GovernanceEntityResponse>;
  updateHome?: Maybe<HomeEntityResponse>;
  updateImpressum?: Maybe<ImpressumEntityResponse>;
  updateLegalNotice?: Maybe<LegalNoticeEntityResponse>;
  updateNavigation?: Maybe<NavigationEntityResponse>;
  updatePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  updateSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  updateSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  updateTableRow?: Maybe<TableRowEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateDropDownItemArgs = {
  data: DropDownItemInput;
};

export type MutationCreateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
};

export type MutationCreateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
};

export type MutationCreateTableRowArgs = {
  data: TableRowInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationDeleteDropDownItemArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapCacheArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTableRowArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSendContactFormArgs = {
  data: ContactFormDataInput;
};

export type MutationUpdateCareerArgs = {
  data: CareerInput;
};

export type MutationUpdateCompanyArgs = {
  data: CompanyInput;
};

export type MutationUpdateCookiePolicyArgs = {
  data: CookiePolicyInput;
};

export type MutationUpdateCookieSettingArgs = {
  data: CookieSettingInput;
};

export type MutationUpdateCookiesBannerArgs = {
  data: CookiesBannerInput;
};

export type MutationUpdateDropDownItemArgs = {
  data: DropDownItemInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateFooterArgs = {
  data: FooterInput;
};

export type MutationUpdateGovernanceArgs = {
  data: GovernanceInput;
};

export type MutationUpdateHomeArgs = {
  data: HomeInput;
};

export type MutationUpdateImpressumArgs = {
  data: ImpressumInput;
};

export type MutationUpdateLegalNoticeArgs = {
  data: LegalNoticeInput;
};

export type MutationUpdateNavigationArgs = {
  data: NavigationInput;
};

export type MutationUpdatePrivacyPolicyArgs = {
  data: PrivacyPolicyInput;
};

export type MutationUpdateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTableRowArgs = {
  data: TableRowInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateWhistleblowingPolicyArgs = {
  data: WhistleblowingPolicyInput;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Navigation = {
  __typename?: 'Navigation';
  closedMenuLinks?: Maybe<Array<Maybe<ComponentNavigationLink>>>;
  companyContactInfo?: Maybe<ComponentNavigationContactInfo>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  openMenuColumns?: Maybe<Array<Maybe<ComponentNavigationOpenMenuColumn>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NavigationClosedMenuLinksArgs = {
  filters?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NavigationOpenMenuColumnsArgs = {
  filters?: InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NavigationEntity = {
  __typename?: 'NavigationEntity';
  attributes?: Maybe<Navigation>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NavigationEntityResponse = {
  __typename?: 'NavigationEntityResponse';
  data?: Maybe<NavigationEntity>;
};

export type NavigationInput = {
  closedMenuLinks?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkInput>>>;
  companyContactInfo?: InputMaybe<ComponentNavigationContactInfoInput>;
  openMenuColumns?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type PrivacyPolicy = {
  __typename?: 'PrivacyPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<PrivacyPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PrivacyPolicyEntity = {
  __typename?: 'PrivacyPolicyEntity';
  attributes?: Maybe<PrivacyPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PrivacyPolicyEntityResponse = {
  __typename?: 'PrivacyPolicyEntityResponse';
  data?: Maybe<PrivacyPolicyEntity>;
};

export type PrivacyPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['PrivacyPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type PrivacyPolicyPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  career?: Maybe<CareerEntityResponse>;
  company?: Maybe<CompanyEntityResponse>;
  cookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  cookieSetting?: Maybe<CookieSettingEntityResponse>;
  cookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  dropDownItem?: Maybe<DropDownItemEntityResponse>;
  dropDownItems?: Maybe<DropDownItemEntityResponseCollection>;
  footer?: Maybe<FooterEntityResponse>;
  governance?: Maybe<GovernanceEntityResponse>;
  home?: Maybe<HomeEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  impressum?: Maybe<ImpressumEntityResponse>;
  legalNotice?: Maybe<LegalNoticeEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  navigation?: Maybe<NavigationEntityResponse>;
  privacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  sitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  sitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  sitemapSitemapCaches?: Maybe<SitemapSitemapCacheEntityResponseCollection>;
  sitemapSitemaps?: Maybe<SitemapSitemapEntityResponseCollection>;
  tableRow?: Maybe<TableRowEntityResponse>;
  tableRows?: Maybe<TableRowEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  whistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
};

export type QueryCareerArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCompanyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiePolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookieSettingArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiesBannerArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryDropDownItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDropDownItemsArgs = {
  filters?: InputMaybe<DropDownItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryFooterArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryGovernanceArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryHomeArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryImpressumArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryLegalNoticeArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryNavigationArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPrivacyPolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySitemapSitemapArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCacheArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCachesArgs = {
  filters?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySitemapSitemapsArgs = {
  filters?: InputMaybe<SitemapSitemapFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTableRowArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTableRowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryWhistleblowingPolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type SitemapSitemap = {
  __typename?: 'SitemapSitemap';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  delta?: Maybe<Scalars['Int']['output']>;
  link_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  sitemap_string: Scalars['String']['output'];
  type?: Maybe<Enum_Sitemapsitemap_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCache = {
  __typename?: 'SitemapSitemapCache';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sitemap_id: Scalars['Int']['output'];
  sitemap_json: Scalars['JSON']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCacheEntity = {
  __typename?: 'SitemapSitemapCacheEntity';
  attributes?: Maybe<SitemapSitemapCache>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapCacheEntityResponse = {
  __typename?: 'SitemapSitemapCacheEntityResponse';
  data?: Maybe<SitemapSitemapCacheEntity>;
};

export type SitemapSitemapCacheEntityResponseCollection = {
  __typename?: 'SitemapSitemapCacheEntityResponseCollection';
  data: Array<SitemapSitemapCacheEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapCacheFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  sitemap_id?: InputMaybe<IntFilterInput>;
  sitemap_json?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapCacheInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_id?: InputMaybe<Scalars['Int']['input']>;
  sitemap_json?: InputMaybe<Scalars['JSON']['input']>;
};

export type SitemapSitemapEntity = {
  __typename?: 'SitemapSitemapEntity';
  attributes?: Maybe<SitemapSitemap>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapEntityResponse = {
  __typename?: 'SitemapSitemapEntityResponse';
  data?: Maybe<SitemapSitemapEntity>;
};

export type SitemapSitemapEntityResponseCollection = {
  __typename?: 'SitemapSitemapEntityResponseCollection';
  data: Array<SitemapSitemapEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  delta?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link_count?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  sitemap_string?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapInput = {
  delta?: InputMaybe<Scalars['Int']['input']>;
  link_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_string?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Sitemapsitemap_Type>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TableRow = {
  __typename?: 'TableRow';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rows?: Maybe<Array<Maybe<ComponentPoliciesRowItem>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TableRowRowsArgs = {
  filters?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowEntity = {
  __typename?: 'TableRowEntity';
  attributes?: Maybe<TableRow>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TableRowEntityResponse = {
  __typename?: 'TableRowEntityResponse';
  data?: Maybe<TableRowEntity>;
};

export type TableRowEntityResponseCollection = {
  __typename?: 'TableRowEntityResponseCollection';
  data: Array<TableRowEntity>;
  meta: ResponseCollectionMeta;
};

export type TableRowFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TableRowFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rows?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TableRowInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rows?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemInput>>>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableRowRelationResponseCollection = {
  __typename?: 'TableRowRelationResponseCollection';
  data: Array<TableRowEntity>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  blurhash?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  blurhash?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type WhistleblowingPolicy = {
  __typename?: 'WhistleblowingPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<WhistleblowingPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WhistleblowingPolicyEntity = {
  __typename?: 'WhistleblowingPolicyEntity';
  attributes?: Maybe<WhistleblowingPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type WhistleblowingPolicyEntityResponse = {
  __typename?: 'WhistleblowingPolicyEntityResponse';
  data?: Maybe<WhistleblowingPolicyEntity>;
};

export type WhistleblowingPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['WhistleblowingPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type WhistleblowingPolicyPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type CareersHeroQueryVariables = Exact<{ [key: string]: never }>;

export type CareersHeroQuery = {
  __typename?: 'Query';
  career?: {
    __typename?: 'CareerEntityResponse';
    data?: {
      __typename?: 'CareerEntity';
      attributes?: {
        __typename?: 'Career';
        hero: {
          __typename?: 'ComponentUiHeroText';
          anchor?: string | null;
          title?: string | null;
          text?: string | null;
        };
      } | null;
    } | null;
  } | null;
};

export type CareersAnimatedSelectQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CareersAnimatedSelectQuery = {
  __typename?: 'Query';
  career?: {
    __typename?: 'CareerEntityResponse';
    data?: {
      __typename?: 'CareerEntity';
      attributes?: {
        __typename?: 'Career';
        animatedSelect?: Array<{
          __typename?: 'ComponentUiCampus';
          anchor?: string | null;
          text?: string | null;
          title?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CareersSeoQueryVariables = Exact<{ [key: string]: never }>;

export type CareersSeoQuery = {
  __typename?: 'Query';
  career?: {
    __typename?: 'CareerEntityResponse';
    data?: {
      __typename?: 'CareerEntity';
      attributes?: {
        __typename?: 'Career';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UploadImageFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      blurhash?: string | null;
    } | null;
  } | null;
};

export type MetaSocialFragment = {
  __typename?: 'ComponentSharedMetaSocial';
  id: string;
  title: string;
  description: string;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SeoFragment = {
  __typename?: 'ComponentSharedSeo';
  id: string;
  canonicalURL?: string | null;
  keywords?: string | null;
  metaDescription: string;
  metaRobots?: string | null;
  metaTitle: string;
  metaViewport?: string | null;
  structuredData?: any | null;
  metaImage?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  metaSocial?: Array<{
    __typename?: 'ComponentSharedMetaSocial';
    id: string;
    title: string;
    description: string;
    socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type SpecFragment = {
  __typename?: 'ComponentUiSpec';
  label?: string | null;
  value?: string | null;
};

export type ImageFragment = {
  __typename?: 'ComponentUiImage';
  alt: string;
  id: string;
  img_desktop: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type HeroFragment = {
  __typename?: 'ComponentUiHero';
  anchor?: string | null;
  title: string;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt: string;
    id: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CardFragment = {
  __typename?: 'ComponentUiCard';
  button?: string | null;
  title?: string | null;
  file?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: { __typename?: 'UploadFile'; url: string } | null;
    } | null;
  } | null;
};

export type UploadFileFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      alternativeText?: string | null;
      blurhash?: string | null;
    } | null;
  } | null;
};

export type NavLinkFragment = {
  __typename?: 'ComponentNavigationLink';
  label: string;
  path: string;
  openInNewTab?: boolean | null;
};

export type ImpressumQueryVariables = Exact<{ [key: string]: never }>;

export type ImpressumQuery = {
  __typename?: 'Query';
  impressum?: {
    __typename?: 'ImpressumEntityResponse';
    data?: {
      __typename?: 'ImpressumEntity';
      attributes?: {
        __typename?: 'Impressum';
        title?: string | null;
        text?: any | null;
        lastUpdatedText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyHeroQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyHeroQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        hero?: {
          __typename?: 'ComponentUiHeroText';
          anchor?: string | null;
          title?: string | null;
          text?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyAnimatedSelectQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyAnimatedSelectQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        animatedSelect?: Array<{
          __typename?: 'ComponentUiCampus';
          anchor?: string | null;
          text?: string | null;
          title?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyProductImageBlockQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        productImageBlock?: Array<{
          __typename?: 'ComponentSharedProductImageBlock';
          anchor?: string | null;
          title?: string | null;
          subtitle?: string | null;
          paragraph?: string | null;
          paragraphTwo?: string | null;
          galleryExtraText?: string | null;
          extraText?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CompanySeoQueryVariables = Exact<{ [key: string]: never }>;

export type CompanySeoQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type FooterLinkFragment = {
  __typename?: 'ComponentFooterLink';
  label?: string | null;
  path?: string | null;
  openInNewTab?: boolean | null;
  file?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type FooterQueryVariables = Exact<{ [key: string]: never }>;

export type FooterQuery = {
  __typename?: 'Query';
  footer?: {
    __typename?: 'FooterEntityResponse';
    data?: {
      __typename?: 'FooterEntity';
      attributes?: {
        __typename?: 'Footer';
        column?: Array<{
          __typename?: 'ComponentFooterColumn';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentFooterLink';
            label?: string | null;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
        socials?: {
          __typename?: 'ComponentFooterSocials';
          label?: string | null;
          path?: string | null;
          instagram?: string | null;
          linkedin?: string | null;
          tiktok?: string | null;
          youtube?: string | null;
        } | null;
        policies?: {
          __typename?: 'ComponentFooterPolicies';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentFooterLink';
            label?: string | null;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        contact?: {
          __typename?: 'ComponentFooterContact';
          title?: string | null;
          address?: {
            __typename?: 'ComponentFooterLink';
            label?: string | null;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          email?: {
            __typename?: 'ComponentFooterLink';
            label?: string | null;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GovHeroQueryVariables = Exact<{ [key: string]: never }>;

export type GovHeroQuery = {
  __typename?: 'Query';
  governance?: {
    __typename?: 'GovernanceEntityResponse';
    data?: {
      __typename?: 'GovernanceEntity';
      attributes?: {
        __typename?: 'Governance';
        hero?: {
          __typename?: 'ComponentUiHero';
          anchor?: string | null;
          title: string;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GovernanceOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type GovernanceOverviewQuery = {
  __typename?: 'Query';
  governance?: {
    __typename?: 'GovernanceEntityResponse';
    data?: {
      __typename?: 'GovernanceEntity';
      attributes?: {
        __typename?: 'Governance';
        governanceSection?: {
          __typename?: 'ComponentUiGovernanceDiagram';
          anchor?: string | null;
          title?: string | null;
          text?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          mobileDropDown?: Array<{
            __typename?: 'ComponentUiGovernanceSection';
            title?: string | null;
            drop_down_item?: {
              __typename?: 'DropDownItemEntityResponse';
              data?: {
                __typename?: 'DropDownItemEntity';
                attributes?: {
                  __typename?: 'DropDownItem';
                  item?: Array<{
                    __typename?: 'ComponentSharedDropItem';
                    label?: string | null;
                  } | null> | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GovInvestorsSectionQueryVariables = Exact<{ [key: string]: never }>;

export type GovInvestorsSectionQuery = {
  __typename?: 'Query';
  governance?: {
    __typename?: 'GovernanceEntityResponse';
    data?: {
      __typename?: 'GovernanceEntity';
      attributes?: {
        __typename?: 'Governance';
        investorsSection?: {
          __typename?: 'ComponentUiInvestorsSection';
          anchor?: string | null;
          title?: string | null;
          subtitle?: string | null;
          investor?: Array<{
            __typename?: 'ComponentUiInvestor';
            name?: string | null;
            note?: string | null;
            percentage?: number | null;
            bgColor?: Enum_Componentuiinvestor_Bgcolor | null;
          } | null> | null;
          icon?: Array<{
            __typename?: 'ComponentUiIcon';
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GovTeamQueryVariables = Exact<{ [key: string]: never }>;

export type GovTeamQuery = {
  __typename?: 'Query';
  governance?: {
    __typename?: 'GovernanceEntityResponse';
    data?: {
      __typename?: 'GovernanceEntity';
      attributes?: {
        __typename?: 'Governance';
        team?: {
          __typename?: 'ComponentUiTeam';
          anchor?: string | null;
          title?: string | null;
          teammates?: Array<{
            __typename?: 'ComponentUiTeamMate';
            alt?: string | null;
            secondPosition?: string | null;
            name?: string | null;
            position?: string | null;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            logos?: {
              __typename?: 'UploadFileRelationResponseCollection';
              data: Array<{
                __typename?: 'UploadFileEntity';
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                } | null;
              }>;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GovDownloadSectionQueryVariables = Exact<{ [key: string]: never }>;

export type GovDownloadSectionQuery = {
  __typename?: 'Query';
  governance?: {
    __typename?: 'GovernanceEntityResponse';
    data?: {
      __typename?: 'GovernanceEntity';
      attributes?: {
        __typename?: 'Governance';
        downloadSection?: {
          __typename?: 'ComponentUiCardsSection';
          anchor?: string | null;
          title?: string | null;
          card?: Array<{
            __typename?: 'ComponentUiCard';
            button?: string | null;
            title?: string | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                attributes?: { __typename?: 'UploadFile'; url: string } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GovProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GovProductImageBlockQuery = {
  __typename?: 'Query';
  governance?: {
    __typename?: 'GovernanceEntityResponse';
    data?: {
      __typename?: 'GovernanceEntity';
      attributes?: {
        __typename?: 'Governance';
        productImageBlock?: {
          __typename?: 'ComponentSharedProductImageBlock';
          anchor?: string | null;
          title?: string | null;
          subtitle?: string | null;
          paragraph?: string | null;
          paragraphTwo?: string | null;
          galleryExtraText?: string | null;
          extraText?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GovSeoQueryVariables = Exact<{ [key: string]: never }>;

export type GovSeoQuery = {
  __typename?: 'Query';
  governance?: {
    __typename?: 'GovernanceEntityResponse';
    data?: {
      __typename?: 'GovernanceEntity';
      attributes?: {
        __typename?: 'Governance';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeHeroQueryVariables = Exact<{ [key: string]: never }>;

export type HomeHeroQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        hero?: {
          __typename?: 'ComponentUiHero';
          anchor?: string | null;
          title: string;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeHeadlineWithImageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HomeHeadlineWithImageQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        headlineWithImageSection?: {
          __typename?: 'ComponentSharedHeadlineWithImageSection';
          anchor?: string | null;
          title?: string | null;
          text?: string | null;
          mobileTableTitle?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          mobileTable?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeTextBlockQueryVariables = Exact<{ [key: string]: never }>;

export type HomeTextBlockQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        textBlock?: {
          __typename?: 'ComponentUiTextBlock';
          anchor?: string | null;
          subtitle?: string | null;
          title?: string | null;
          paragraph?: string | null;
          textSection?: Array<{
            __typename?: 'ComponentUiTextSection';
            title?: string | null;
            text?: any | null;
            icon_svg?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeSliderImageSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HomeSliderImageSectionQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        sliderImageSection?: {
          __typename?: 'ComponentUiHistory';
          anchor?: string | null;
          title?: string | null;
          item?: Array<{
            __typename?: 'ComponentUiHistoryItem';
            title?: string | null;
            year?: string | null;
            alt?: string | null;
            description?: string | null;
            linkText?: string | null;
            linkPath?: string | null;
            image_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeValuesQueryVariables = Exact<{ [key: string]: never }>;

export type HomeValuesQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        values?: {
          __typename?: 'ComponentUiValues';
          anchor?: string | null;
          title?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            title?: string | null;
            text?: string | null;
            icon_svg?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeMapSectionQueryVariables = Exact<{ [key: string]: never }>;

export type HomeMapSectionQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        mapSection?: {
          __typename?: 'ComponentSharedMapSection';
          title?: string | null;
          anchor?: string | null;
          mapItems?: Array<{
            __typename?: 'ComponentUiMapItem';
            tabTitle: string;
            tagColor: Enum_Componentuimapitem_Tagcolor;
            blackText?: boolean | null;
            leftMapTitle: string;
            rightMapTitle: string;
            leftMapImage: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            leftMapImageMobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            rightMapImage: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            rightMapImageMobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeLargeImageSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HomeLargeImageSectionQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt: string;
            id: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type HomeNewsroomQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          cards?: Array<{
            __typename?: 'ComponentUiNewsroomCard';
            title: string;
            link: string;
            openInNewTab?: boolean | null;
            image: {
              __typename?: 'ComponentUiImage';
              alt: string;
              id: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeSeoQueryVariables = Exact<{ [key: string]: never }>;

export type HomeSeoQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type OpenMenuColumnsFragment = {
  __typename?: 'ComponentNavigationOpenMenuColumn';
  label?: string | null;
  link?: Array<{
    __typename?: 'ComponentNavigationLink';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
  } | null> | null;
  socials?: {
    __typename?: 'ComponentNavigationSocials';
    instagram?: string | null;
    linkedIn?: string | null;
    tiktok?: string | null;
    youtube?: string | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = {
  __typename?: 'Query';
  navigation?: {
    __typename?: 'NavigationEntityResponse';
    data?: {
      __typename?: 'NavigationEntity';
      attributes?: {
        __typename?: 'Navigation';
        closedMenuLinks?: Array<{
          __typename?: 'ComponentNavigationLink';
          label: string;
          path: string;
          openInNewTab?: boolean | null;
        } | null> | null;
        openMenuColumns?: Array<{
          __typename?: 'ComponentNavigationOpenMenuColumn';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentNavigationLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null> | null;
          socials?: {
            __typename?: 'ComponentNavigationSocials';
            instagram?: string | null;
            linkedIn?: string | null;
            tiktok?: string | null;
            youtube?: string | null;
          } | null;
        } | null> | null;
        companyContactInfo?: {
          __typename?: 'ComponentNavigationContactInfo';
          name?: string | null;
          address?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesDevider_Fragment = {
  __typename?: 'ComponentPoliciesDevider';
  devider?: boolean | null;
};

type PolicyFields_ComponentPoliciesDownloadLink_Fragment = {
  __typename?: 'ComponentPoliciesDownloadLink';
  label?: string | null;
  link?: string | null;
};

type PolicyFields_ComponentPoliciesMainHeading_Fragment = {
  __typename?: 'ComponentPoliciesMainHeading';
  text?: any | null;
};

type PolicyFields_ComponentPoliciesTableTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTableTextContent';
  richText?: any | null;
  tableHead?: Array<{
    __typename?: 'ComponentPoliciesTableHeading';
    headingItem?: string | null;
  } | null> | null;
  tableRow?: {
    __typename?: 'ComponentPoliciesTabelRow';
    table_rows?: {
      __typename?: 'TableRowRelationResponseCollection';
      data: Array<{
        __typename?: 'TableRowEntity';
        attributes?: {
          __typename?: 'TableRow';
          rows?: Array<{
            __typename?: 'ComponentPoliciesRowItem';
            item?: string | null;
          } | null> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesTitleTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  text?: any | null;
};

type PolicyFields_Error_Fragment = { __typename?: 'Error' };

export type PolicyFieldsFragment =
  | PolicyFields_ComponentPoliciesDevider_Fragment
  | PolicyFields_ComponentPoliciesDownloadLink_Fragment
  | PolicyFields_ComponentPoliciesMainHeading_Fragment
  | PolicyFields_ComponentPoliciesTableTextContent_Fragment
  | PolicyFields_ComponentPoliciesTitleTextContent_Fragment
  | PolicyFields_Error_Fragment;

export type PrivacyPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PrivacyPolicyQuery = {
  __typename?: 'Query';
  privacyPolicy?: {
    __typename?: 'PrivacyPolicyEntityResponse';
    data?: {
      __typename?: 'PrivacyPolicyEntity';
      attributes?: {
        __typename?: 'PrivacyPolicy';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTabelRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiePolicyQueryVariables = Exact<{ [key: string]: never }>;

export type CookiePolicyQuery = {
  __typename?: 'Query';
  cookiePolicy?: {
    __typename?: 'CookiePolicyEntityResponse';
    data?: {
      __typename?: 'CookiePolicyEntity';
      attributes?: {
        __typename?: 'CookiePolicy';
        cookiePolicy?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTabelRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type LegalNoticeQueryVariables = Exact<{ [key: string]: never }>;

export type LegalNoticeQuery = {
  __typename?: 'Query';
  legalNotice?: {
    __typename?: 'LegalNoticeEntityResponse';
    data?: {
      __typename?: 'LegalNoticeEntity';
      attributes?: {
        __typename?: 'LegalNotice';
        legalNotice?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTabelRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type WhistleblowingPolicyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WhistleblowingPolicyQuery = {
  __typename?: 'Query';
  whistleblowingPolicy?: {
    __typename?: 'WhistleblowingPolicyEntityResponse';
    data?: {
      __typename?: 'WhistleblowingPolicyEntity';
      attributes?: {
        __typename?: 'WhistleblowingPolicy';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTabelRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type CookiesSettingsQuery = {
  __typename?: 'Query';
  cookieSetting?: {
    __typename?: 'CookieSettingEntityResponse';
    data?: {
      __typename?: 'CookieSettingEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookieSetting';
        actionButtonLabel?: string | null;
        necessaryCookiesHeading?: string | null;
        optionalCookiesHeading?: string | null;
        title?: string | null;
        necessaryCookiesTable?: {
          __typename?: 'ComponentPoliciesCookiesTable';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTabelRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        optionalCookiesTale?: {
          __typename?: 'ComponentPoliciesCookiesTable';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTabelRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        redirectButton?: {
          __typename?: 'ComponentUiButton';
          label: string;
          path: string;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesBarQueryVariables = Exact<{ [key: string]: never }>;

export type CookiesBarQuery = {
  __typename?: 'Query';
  cookiesBanner?: {
    __typename?: 'CookiesBannerEntityResponse';
    data?: {
      __typename?: 'CookiesBannerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookiesBanner';
        heading?: string | null;
        editButtonLabel?: string | null;
        declineButtonLabel?: string | null;
        acceptButtonLabel?: string | null;
        text?: any | null;
        extraLinks?: Array<{
          __typename?: 'ComponentUiButton';
          label: string;
          path: string;
          openInNewTab?: boolean | null;
          file?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type HeadlineWithImageSectionFragment = {
  __typename?: 'ComponentSharedHeadlineWithImageSection';
  anchor?: string | null;
  title?: string | null;
  text?: string | null;
  mobileTableTitle?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt: string;
    id: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  mobileTable?: Array<{
    __typename?: 'ComponentUiSpec';
    label?: string | null;
    value?: string | null;
  } | null> | null;
};

export type ProductImageBlockFragment = {
  __typename?: 'ComponentSharedProductImageBlock';
  anchor?: string | null;
  title?: string | null;
  subtitle?: string | null;
  paragraph?: string | null;
  paragraphTwo?: string | null;
  galleryExtraText?: string | null;
  extraText?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt: string;
    id: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  largeSpec?: Array<{
    __typename?: 'ComponentUiSpec';
    label?: string | null;
    value?: string | null;
  } | null> | null;
};

export type LargeImageSectionFragment = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: string | null;
  title?: string | null;
  description?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt: string;
    id: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type MapSectionFragment = {
  __typename?: 'ComponentSharedMapSection';
  title?: string | null;
  anchor?: string | null;
  mapItems?: Array<{
    __typename?: 'ComponentUiMapItem';
    tabTitle: string;
    tagColor: Enum_Componentuimapitem_Tagcolor;
    blackText?: boolean | null;
    leftMapTitle: string;
    rightMapTitle: string;
    leftMapImage: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    leftMapImageMobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    rightMapImage: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    rightMapImageMobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type NewsroomFragment = {
  __typename?: 'ComponentSharedNewsroom';
  title?: string | null;
  anchor?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  cards?: Array<{
    __typename?: 'ComponentUiNewsroomCard';
    title: string;
    link: string;
    openInNewTab?: boolean | null;
    image: {
      __typename?: 'ComponentUiImage';
      alt: string;
      id: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    };
  } | null> | null;
};

export type HeroVideoSectionFragment = {
  __typename?: 'ComponentSharedHeroVideoSection';
  title?: string | null;
  subtitle?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroVideo?: {
    __typename?: 'ComponentUiVideo';
    poster_alt?: string | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    autoplay?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    loop?: boolean | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    poster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HeroTextFragment = {
  __typename?: 'ComponentUiHeroText';
  anchor?: string | null;
  title?: string | null;
  text?: string | null;
};

export type ButtonFragment = {
  __typename?: 'ComponentUiButton';
  label: string;
  path: string;
  openInNewTab?: boolean | null;
  file?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type TeamFragment = {
  __typename?: 'ComponentUiTeam';
  anchor?: string | null;
  title?: string | null;
  teammates?: Array<{
    __typename?: 'ComponentUiTeamMate';
    alt?: string | null;
    secondPosition?: string | null;
    name?: string | null;
    position?: string | null;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    logos?: {
      __typename?: 'UploadFileRelationResponseCollection';
      data: Array<{
        __typename?: 'UploadFileEntity';
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
        } | null;
      }>;
    } | null;
  } | null> | null;
};

export type GovernanceFragment = {
  __typename?: 'ComponentUiGovernanceDiagram';
  anchor?: string | null;
  title?: string | null;
  text?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt: string;
    id: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  mobileDropDown?: Array<{
    __typename?: 'ComponentUiGovernanceSection';
    title?: string | null;
    drop_down_item?: {
      __typename?: 'DropDownItemEntityResponse';
      data?: {
        __typename?: 'DropDownItemEntity';
        attributes?: {
          __typename?: 'DropDownItem';
          item?: Array<{
            __typename?: 'ComponentSharedDropItem';
            label?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type DownloadSectionFragment = {
  __typename?: 'ComponentUiCardsSection';
  anchor?: string | null;
  title?: string | null;
  card?: Array<{
    __typename?: 'ComponentUiCard';
    button?: string | null;
    title?: string | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        attributes?: { __typename?: 'UploadFile'; url: string } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type AnimatedSelectFragment = {
  __typename?: 'ComponentUiCampus';
  anchor?: string | null;
  text?: string | null;
  title?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt: string;
    id: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type TextBlockFragment = {
  __typename?: 'ComponentUiTextBlock';
  anchor?: string | null;
  subtitle?: string | null;
  title?: string | null;
  paragraph?: string | null;
  textSection?: Array<{
    __typename?: 'ComponentUiTextSection';
    title?: string | null;
    text?: any | null;
    icon_svg?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt: string;
    id: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HistoryFragment = {
  __typename?: 'ComponentUiHistory';
  anchor?: string | null;
  title?: string | null;
  item?: Array<{
    __typename?: 'ComponentUiHistoryItem';
    title?: string | null;
    year?: string | null;
    alt?: string | null;
    description?: string | null;
    linkText?: string | null;
    linkPath?: string | null;
    image_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type ValuesFragment = {
  __typename?: 'ComponentUiValues';
  anchor?: string | null;
  title?: string | null;
  iconCard?: Array<{
    __typename?: 'ComponentUiIconCard';
    title?: string | null;
    text?: string | null;
    icon_svg?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type InvestorsSectionFragment = {
  __typename?: 'ComponentUiInvestorsSection';
  anchor?: string | null;
  title?: string | null;
  subtitle?: string | null;
  investor?: Array<{
    __typename?: 'ComponentUiInvestor';
    name?: string | null;
    note?: string | null;
    percentage?: number | null;
    bgColor?: Enum_Componentuiinvestor_Bgcolor | null;
  } | null> | null;
  icon?: Array<{
    __typename?: 'ComponentUiIcon';
    alt: string;
    svg: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    };
  } | null> | null;
};

export type FacilitiesFragment = {
  __typename?: 'ComponentUiFacilitiesSection';
  anchor?: string | null;
  title?: string | null;
  map?: Array<{
    __typename?: 'ComponentUiMap';
    name?: string | null;
    alt?: string | null;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export const UploadImageFragmentDoc = gql`
  fragment uploadImage on UploadFileEntityResponse {
    data {
      id
      __typename
      attributes {
        url
        blurhash
      }
    }
  }
`;
export const MetaSocialFragmentDoc = gql`
  fragment metaSocial on ComponentSharedMetaSocial {
    id
    title
    description
    socialNetwork
    image {
      ...uploadImage
    }
  }
  ${UploadImageFragmentDoc}
`;
export const SeoFragmentDoc = gql`
  fragment seo on ComponentSharedSeo {
    id
    canonicalURL
    keywords
    metaDescription
    metaImage {
      ...uploadImage
    }
    metaRobots
    metaSocial {
      ...metaSocial
    }
    metaTitle
    metaViewport
    structuredData
  }
  ${UploadImageFragmentDoc}
  ${MetaSocialFragmentDoc}
`;
export const SpecFragmentDoc = gql`
  fragment spec on ComponentUiSpec {
    label
    value
  }
`;
export const ButtonFragmentDoc = gql`
  fragment button on ComponentUiButton {
    label
    path
    openInNewTab
    file {
      ...uploadImage
    }
  }
  ${UploadImageFragmentDoc}
`;
export const ImageFragmentDoc = gql`
  fragment image on ComponentUiImage {
    alt
    id
    img_desktop {
      ...uploadImage
    }
    img_mobile {
      ...uploadImage
    }
  }
  ${UploadImageFragmentDoc}
`;
export const HeroFragmentDoc = gql`
  fragment hero on ComponentUiHero {
    anchor
    title
    button {
      ...button
    }
    image {
      ...image
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const UploadFileFragmentDoc = gql`
  fragment uploadFile on UploadFileEntityResponse {
    data {
      id
      attributes {
        url
        alternativeText
        blurhash
      }
    }
  }
`;
export const FooterLinkFragmentDoc = gql`
  fragment footerLink on ComponentFooterLink {
    label
    path
    file {
      ...uploadFile
    }
    openInNewTab
  }
  ${UploadFileFragmentDoc}
`;
export const NavLinkFragmentDoc = gql`
  fragment navLink on ComponentNavigationLink {
    label
    path
    openInNewTab
  }
`;
export const OpenMenuColumnsFragmentDoc = gql`
  fragment openMenuColumns on ComponentNavigationOpenMenuColumn {
    label
    link {
      ...navLink
    }
    socials {
      instagram
      linkedIn
      tiktok
      youtube
    }
  }
  ${NavLinkFragmentDoc}
`;
export const PolicyFieldsFragmentDoc = gql`
  fragment policyFields on PrivacyPolicyPoliciesDynamicZone {
    ... on ComponentPoliciesDownloadLink {
      label
      link
    }
    ... on ComponentPoliciesMainHeading {
      text
    }
    ... on ComponentPoliciesTitleTextContent {
      text
    }
    ... on ComponentPoliciesTableTextContent {
      richText
      tableHead {
        headingItem
      }
      tableRow {
        table_rows {
          data {
            attributes {
              rows {
                item
              }
            }
          }
        }
      }
    }
    ... on ComponentPoliciesDevider {
      devider
    }
  }
`;
export const HeadlineWithImageSectionFragmentDoc = gql`
  fragment headlineWithImageSection on ComponentSharedHeadlineWithImageSection {
    anchor
    title
    text
    image {
      ...image
    }
    button {
      ...button
    }
    mobileTableTitle
    mobileTable {
      label
      value
    }
  }
  ${ImageFragmentDoc}
  ${ButtonFragmentDoc}
`;
export const ProductImageBlockFragmentDoc = gql`
  fragment productImageBlock on ComponentSharedProductImageBlock {
    anchor
    title
    subtitle
    paragraph
    paragraphTwo
    button {
      ...button
    }
    image {
      ...image
    }
    largeSpec {
      label
      value
    }
    galleryExtraText
    extraText
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const LargeImageSectionFragmentDoc = gql`
  fragment largeImageSection on ComponentSharedLargeImageSection {
    anchor
    title
    description
    button {
      ...button
    }
    image {
      ...image
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const MapSectionFragmentDoc = gql`
  fragment mapSection on ComponentSharedMapSection {
    title
    anchor
    mapItems {
      tabTitle
      tagColor
      blackText
      leftMapTitle
      leftMapImage {
        ...uploadImage
      }
      leftMapImageMobile {
        ...uploadImage
      }
      rightMapTitle
      rightMapImage {
        ...uploadImage
      }
      rightMapImageMobile {
        ...uploadImage
      }
    }
  }
  ${UploadImageFragmentDoc}
`;
export const NewsroomFragmentDoc = gql`
  fragment newsroom on ComponentSharedNewsroom {
    title
    anchor
    button {
      ...button
    }
    cards {
      title
      link
      image {
        ...image
      }
      openInNewTab
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const HeroVideoSectionFragmentDoc = gql`
  fragment heroVideoSection on ComponentSharedHeroVideoSection {
    title
    subtitle
    button {
      ...button
    }
    heroVideo {
      video {
        ...uploadImage
      }
      poster {
        ...uploadImage
      }
      poster_alt
      controls
      fullscreen
      autoplay
      muted
      pauseVideo
      loop
    }
  }
  ${ButtonFragmentDoc}
  ${UploadImageFragmentDoc}
`;
export const HeroTextFragmentDoc = gql`
  fragment heroText on ComponentUiHeroText {
    anchor
    title
    text
  }
`;
export const TeamFragmentDoc = gql`
  fragment team on ComponentUiTeam {
    anchor
    title
    teammates {
      alt
      img_desktop {
        ...uploadImage
      }
      img_mobile {
        ...uploadImage
      }
      logos {
        data {
          attributes {
            url
            alternativeText
          }
        }
      }
      secondPosition
      name
      position
    }
  }
  ${UploadImageFragmentDoc}
`;
export const GovernanceFragmentDoc = gql`
  fragment governance on ComponentUiGovernanceDiagram {
    anchor
    title
    text
    button {
      ...button
    }
    image {
      ...image
    }
    mobileDropDown {
      title
      drop_down_item {
        data {
          attributes {
            item {
              label
            }
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const CardFragmentDoc = gql`
  fragment card on ComponentUiCard {
    button
    file {
      data {
        attributes {
          url
        }
      }
    }
    title
  }
`;
export const DownloadSectionFragmentDoc = gql`
  fragment downloadSection on ComponentUiCardsSection {
    anchor
    title
    card {
      ...card
    }
  }
  ${CardFragmentDoc}
`;
export const AnimatedSelectFragmentDoc = gql`
  fragment animatedSelect on ComponentUiCampus {
    anchor
    text
    title
    image {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const TextBlockFragmentDoc = gql`
  fragment textBlock on ComponentUiTextBlock {
    anchor
    subtitle
    title
    paragraph
    textSection {
      title
      text
      icon_svg {
        ...uploadImage
      }
    }
    image {
      ...image
    }
  }
  ${UploadImageFragmentDoc}
  ${ImageFragmentDoc}
`;
export const HistoryFragmentDoc = gql`
  fragment history on ComponentUiHistory {
    anchor
    title
    item {
      title
      year
      alt
      description
      linkText
      linkPath
      image_desktop {
        ...uploadImage
      }
      img_mobile {
        ...uploadImage
      }
    }
  }
  ${UploadImageFragmentDoc}
`;
export const ValuesFragmentDoc = gql`
  fragment values on ComponentUiValues {
    anchor
    title
    iconCard {
      title
      text
      icon_svg {
        ...uploadImage
      }
    }
  }
  ${UploadImageFragmentDoc}
`;
export const InvestorsSectionFragmentDoc = gql`
  fragment investorsSection on ComponentUiInvestorsSection {
    anchor
    title
    subtitle
    investor {
      name
      note
      percentage
      bgColor
    }
    icon {
      alt
      svg {
        ...uploadImage
      }
    }
  }
  ${UploadImageFragmentDoc}
`;
export const FacilitiesFragmentDoc = gql`
  fragment facilities on ComponentUiFacilitiesSection {
    anchor
    title
    map {
      name
      alt
      img_desktop {
        ...uploadImage
      }
      img_mobile {
        ...uploadImage
      }
    }
  }
  ${UploadImageFragmentDoc}
`;
export const CareersHeroDocument = gql`
  query CareersHero {
    career {
      data {
        attributes {
          hero {
            ...heroText
          }
        }
      }
    }
  }
  ${HeroTextFragmentDoc}
`;

/**
 * __useCareersHeroQuery__
 *
 * To run a query within a React component, call `useCareersHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareersHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CareersHeroQuery,
    CareersHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareersHeroQuery, CareersHeroQueryVariables>(
    CareersHeroDocument,
    options,
  );
}
export function useCareersHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareersHeroQuery,
    CareersHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareersHeroQuery, CareersHeroQueryVariables>(
    CareersHeroDocument,
    options,
  );
}
export function useCareersHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CareersHeroQuery,
        CareersHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CareersHeroQuery, CareersHeroQueryVariables>(
    CareersHeroDocument,
    options,
  );
}
export type CareersHeroQueryHookResult = ReturnType<typeof useCareersHeroQuery>;
export type CareersHeroLazyQueryHookResult = ReturnType<
  typeof useCareersHeroLazyQuery
>;
export type CareersHeroSuspenseQueryHookResult = ReturnType<
  typeof useCareersHeroSuspenseQuery
>;
export type CareersHeroQueryResult = Apollo.QueryResult<
  CareersHeroQuery,
  CareersHeroQueryVariables
>;
export const CareersAnimatedSelectDocument = gql`
  query CareersAnimatedSelect {
    career {
      data {
        attributes {
          animatedSelect {
            ...animatedSelect
          }
        }
      }
    }
  }
  ${AnimatedSelectFragmentDoc}
`;

/**
 * __useCareersAnimatedSelectQuery__
 *
 * To run a query within a React component, call `useCareersAnimatedSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersAnimatedSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersAnimatedSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareersAnimatedSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CareersAnimatedSelectQuery,
    CareersAnimatedSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CareersAnimatedSelectQuery,
    CareersAnimatedSelectQueryVariables
  >(CareersAnimatedSelectDocument, options);
}
export function useCareersAnimatedSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareersAnimatedSelectQuery,
    CareersAnimatedSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareersAnimatedSelectQuery,
    CareersAnimatedSelectQueryVariables
  >(CareersAnimatedSelectDocument, options);
}
export function useCareersAnimatedSelectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CareersAnimatedSelectQuery,
        CareersAnimatedSelectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CareersAnimatedSelectQuery,
    CareersAnimatedSelectQueryVariables
  >(CareersAnimatedSelectDocument, options);
}
export type CareersAnimatedSelectQueryHookResult = ReturnType<
  typeof useCareersAnimatedSelectQuery
>;
export type CareersAnimatedSelectLazyQueryHookResult = ReturnType<
  typeof useCareersAnimatedSelectLazyQuery
>;
export type CareersAnimatedSelectSuspenseQueryHookResult = ReturnType<
  typeof useCareersAnimatedSelectSuspenseQuery
>;
export type CareersAnimatedSelectQueryResult = Apollo.QueryResult<
  CareersAnimatedSelectQuery,
  CareersAnimatedSelectQueryVariables
>;
export const CareersSeoDocument = gql`
  query CareersSeo {
    career {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useCareersSeoQuery__
 *
 * To run a query within a React component, call `useCareersSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareersSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CareersSeoQuery,
    CareersSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareersSeoQuery, CareersSeoQueryVariables>(
    CareersSeoDocument,
    options,
  );
}
export function useCareersSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareersSeoQuery,
    CareersSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareersSeoQuery, CareersSeoQueryVariables>(
    CareersSeoDocument,
    options,
  );
}
export function useCareersSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CareersSeoQuery,
        CareersSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CareersSeoQuery, CareersSeoQueryVariables>(
    CareersSeoDocument,
    options,
  );
}
export type CareersSeoQueryHookResult = ReturnType<typeof useCareersSeoQuery>;
export type CareersSeoLazyQueryHookResult = ReturnType<
  typeof useCareersSeoLazyQuery
>;
export type CareersSeoSuspenseQueryHookResult = ReturnType<
  typeof useCareersSeoSuspenseQuery
>;
export type CareersSeoQueryResult = Apollo.QueryResult<
  CareersSeoQuery,
  CareersSeoQueryVariables
>;
export const ImpressumDocument = gql`
  query Impressum {
    impressum {
      data {
        attributes {
          title
          text
          lastUpdatedText
        }
      }
    }
  }
`;

/**
 * __useImpressumQuery__
 *
 * To run a query within a React component, call `useImpressumQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpressumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpressumQuery({
 *   variables: {
 *   },
 * });
 */
export function useImpressumQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImpressumQuery,
    ImpressumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export function useImpressumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpressumQuery,
    ImpressumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export function useImpressumSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ImpressumQuery, ImpressumQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export type ImpressumQueryHookResult = ReturnType<typeof useImpressumQuery>;
export type ImpressumLazyQueryHookResult = ReturnType<
  typeof useImpressumLazyQuery
>;
export type ImpressumSuspenseQueryHookResult = ReturnType<
  typeof useImpressumSuspenseQuery
>;
export type ImpressumQueryResult = Apollo.QueryResult<
  ImpressumQuery,
  ImpressumQueryVariables
>;
export const CompanyHeroDocument = gql`
  query CompanyHero {
    company {
      data {
        attributes {
          hero {
            ...heroText
          }
        }
      }
    }
  }
  ${HeroTextFragmentDoc}
`;

/**
 * __useCompanyHeroQuery__
 *
 * To run a query within a React component, call `useCompanyHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyHeroQuery,
    CompanyHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export function useCompanyHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyHeroQuery,
    CompanyHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export function useCompanyHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyHeroQuery,
        CompanyHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export type CompanyHeroQueryHookResult = ReturnType<typeof useCompanyHeroQuery>;
export type CompanyHeroLazyQueryHookResult = ReturnType<
  typeof useCompanyHeroLazyQuery
>;
export type CompanyHeroSuspenseQueryHookResult = ReturnType<
  typeof useCompanyHeroSuspenseQuery
>;
export type CompanyHeroQueryResult = Apollo.QueryResult<
  CompanyHeroQuery,
  CompanyHeroQueryVariables
>;
export const CompanyAnimatedSelectDocument = gql`
  query CompanyAnimatedSelect {
    company {
      data {
        attributes {
          animatedSelect {
            ...animatedSelect
          }
        }
      }
    }
  }
  ${AnimatedSelectFragmentDoc}
`;

/**
 * __useCompanyAnimatedSelectQuery__
 *
 * To run a query within a React component, call `useCompanyAnimatedSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAnimatedSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAnimatedSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyAnimatedSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyAnimatedSelectQuery,
    CompanyAnimatedSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyAnimatedSelectQuery,
    CompanyAnimatedSelectQueryVariables
  >(CompanyAnimatedSelectDocument, options);
}
export function useCompanyAnimatedSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyAnimatedSelectQuery,
    CompanyAnimatedSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyAnimatedSelectQuery,
    CompanyAnimatedSelectQueryVariables
  >(CompanyAnimatedSelectDocument, options);
}
export function useCompanyAnimatedSelectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyAnimatedSelectQuery,
        CompanyAnimatedSelectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyAnimatedSelectQuery,
    CompanyAnimatedSelectQueryVariables
  >(CompanyAnimatedSelectDocument, options);
}
export type CompanyAnimatedSelectQueryHookResult = ReturnType<
  typeof useCompanyAnimatedSelectQuery
>;
export type CompanyAnimatedSelectLazyQueryHookResult = ReturnType<
  typeof useCompanyAnimatedSelectLazyQuery
>;
export type CompanyAnimatedSelectSuspenseQueryHookResult = ReturnType<
  typeof useCompanyAnimatedSelectSuspenseQuery
>;
export type CompanyAnimatedSelectQueryResult = Apollo.QueryResult<
  CompanyAnimatedSelectQuery,
  CompanyAnimatedSelectQueryVariables
>;
export const CompanyProductImageBlockDocument = gql`
  query CompanyProductImageBlock {
    company {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useCompanyProductImageBlockQuery__
 *
 * To run a query within a React component, call `useCompanyProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyProductImageBlockQuery,
    CompanyProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyProductImageBlockQuery,
    CompanyProductImageBlockQueryVariables
  >(CompanyProductImageBlockDocument, options);
}
export function useCompanyProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyProductImageBlockQuery,
    CompanyProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyProductImageBlockQuery,
    CompanyProductImageBlockQueryVariables
  >(CompanyProductImageBlockDocument, options);
}
export function useCompanyProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyProductImageBlockQuery,
        CompanyProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyProductImageBlockQuery,
    CompanyProductImageBlockQueryVariables
  >(CompanyProductImageBlockDocument, options);
}
export type CompanyProductImageBlockQueryHookResult = ReturnType<
  typeof useCompanyProductImageBlockQuery
>;
export type CompanyProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useCompanyProductImageBlockLazyQuery
>;
export type CompanyProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useCompanyProductImageBlockSuspenseQuery
>;
export type CompanyProductImageBlockQueryResult = Apollo.QueryResult<
  CompanyProductImageBlockQuery,
  CompanyProductImageBlockQueryVariables
>;
export const CompanySeoDocument = gql`
  query CompanySeo {
    company {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useCompanySeoQuery__
 *
 * To run a query within a React component, call `useCompanySeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanySeoQuery,
    CompanySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export function useCompanySeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanySeoQuery,
    CompanySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export function useCompanySeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanySeoQuery,
        CompanySeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export type CompanySeoQueryHookResult = ReturnType<typeof useCompanySeoQuery>;
export type CompanySeoLazyQueryHookResult = ReturnType<
  typeof useCompanySeoLazyQuery
>;
export type CompanySeoSuspenseQueryHookResult = ReturnType<
  typeof useCompanySeoSuspenseQuery
>;
export type CompanySeoQueryResult = Apollo.QueryResult<
  CompanySeoQuery,
  CompanySeoQueryVariables
>;
export const FooterDocument = gql`
  query Footer {
    footer {
      data {
        attributes {
          column {
            label
            link {
              ...footerLink
            }
          }
          socials {
            label
            path
            instagram
            linkedin
            tiktok
            youtube
          }
          policies {
            label
            link {
              ...footerLink
            }
          }
          contact {
            title
            address {
              ...footerLink
            }
            email {
              ...footerLink
            }
          }
        }
      }
    }
  }
  ${FooterLinkFragmentDoc}
`;

/**
 * __useFooterQuery__
 *
 * To run a query within a React component, call `useFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQuery({
 *   variables: {
 *   },
 * });
 */
export function useFooterQuery(
  baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterSuspenseQueryHookResult = ReturnType<
  typeof useFooterSuspenseQuery
>;
export type FooterQueryResult = Apollo.QueryResult<
  FooterQuery,
  FooterQueryVariables
>;
export const GovHeroDocument = gql`
  query GovHero {
    governance {
      data {
        attributes {
          hero {
            ...hero
          }
        }
      }
    }
  }
  ${HeroFragmentDoc}
`;

/**
 * __useGovHeroQuery__
 *
 * To run a query within a React component, call `useGovHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useGovHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<GovHeroQuery, GovHeroQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GovHeroQuery, GovHeroQueryVariables>(
    GovHeroDocument,
    options,
  );
}
export function useGovHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GovHeroQuery,
    GovHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GovHeroQuery, GovHeroQueryVariables>(
    GovHeroDocument,
    options,
  );
}
export function useGovHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GovHeroQuery, GovHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GovHeroQuery, GovHeroQueryVariables>(
    GovHeroDocument,
    options,
  );
}
export type GovHeroQueryHookResult = ReturnType<typeof useGovHeroQuery>;
export type GovHeroLazyQueryHookResult = ReturnType<typeof useGovHeroLazyQuery>;
export type GovHeroSuspenseQueryHookResult = ReturnType<
  typeof useGovHeroSuspenseQuery
>;
export type GovHeroQueryResult = Apollo.QueryResult<
  GovHeroQuery,
  GovHeroQueryVariables
>;
export const GovernanceOverviewDocument = gql`
  query GovernanceOverview {
    governance {
      data {
        attributes {
          governanceSection {
            ...governance
          }
        }
      }
    }
  }
  ${GovernanceFragmentDoc}
`;

/**
 * __useGovernanceOverviewQuery__
 *
 * To run a query within a React component, call `useGovernanceOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovernanceOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovernanceOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGovernanceOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GovernanceOverviewQuery,
    GovernanceOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GovernanceOverviewQuery,
    GovernanceOverviewQueryVariables
  >(GovernanceOverviewDocument, options);
}
export function useGovernanceOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GovernanceOverviewQuery,
    GovernanceOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GovernanceOverviewQuery,
    GovernanceOverviewQueryVariables
  >(GovernanceOverviewDocument, options);
}
export function useGovernanceOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GovernanceOverviewQuery,
        GovernanceOverviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GovernanceOverviewQuery,
    GovernanceOverviewQueryVariables
  >(GovernanceOverviewDocument, options);
}
export type GovernanceOverviewQueryHookResult = ReturnType<
  typeof useGovernanceOverviewQuery
>;
export type GovernanceOverviewLazyQueryHookResult = ReturnType<
  typeof useGovernanceOverviewLazyQuery
>;
export type GovernanceOverviewSuspenseQueryHookResult = ReturnType<
  typeof useGovernanceOverviewSuspenseQuery
>;
export type GovernanceOverviewQueryResult = Apollo.QueryResult<
  GovernanceOverviewQuery,
  GovernanceOverviewQueryVariables
>;
export const GovInvestorsSectionDocument = gql`
  query GovInvestorsSection {
    governance {
      data {
        attributes {
          investorsSection {
            ...investorsSection
          }
        }
      }
    }
  }
  ${InvestorsSectionFragmentDoc}
`;

/**
 * __useGovInvestorsSectionQuery__
 *
 * To run a query within a React component, call `useGovInvestorsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovInvestorsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovInvestorsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGovInvestorsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GovInvestorsSectionQuery,
    GovInvestorsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GovInvestorsSectionQuery,
    GovInvestorsSectionQueryVariables
  >(GovInvestorsSectionDocument, options);
}
export function useGovInvestorsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GovInvestorsSectionQuery,
    GovInvestorsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GovInvestorsSectionQuery,
    GovInvestorsSectionQueryVariables
  >(GovInvestorsSectionDocument, options);
}
export function useGovInvestorsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GovInvestorsSectionQuery,
        GovInvestorsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GovInvestorsSectionQuery,
    GovInvestorsSectionQueryVariables
  >(GovInvestorsSectionDocument, options);
}
export type GovInvestorsSectionQueryHookResult = ReturnType<
  typeof useGovInvestorsSectionQuery
>;
export type GovInvestorsSectionLazyQueryHookResult = ReturnType<
  typeof useGovInvestorsSectionLazyQuery
>;
export type GovInvestorsSectionSuspenseQueryHookResult = ReturnType<
  typeof useGovInvestorsSectionSuspenseQuery
>;
export type GovInvestorsSectionQueryResult = Apollo.QueryResult<
  GovInvestorsSectionQuery,
  GovInvestorsSectionQueryVariables
>;
export const GovTeamDocument = gql`
  query GovTeam {
    governance {
      data {
        attributes {
          team {
            ...team
          }
        }
      }
    }
  }
  ${TeamFragmentDoc}
`;

/**
 * __useGovTeamQuery__
 *
 * To run a query within a React component, call `useGovTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGovTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<GovTeamQuery, GovTeamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GovTeamQuery, GovTeamQueryVariables>(
    GovTeamDocument,
    options,
  );
}
export function useGovTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GovTeamQuery,
    GovTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GovTeamQuery, GovTeamQueryVariables>(
    GovTeamDocument,
    options,
  );
}
export function useGovTeamSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GovTeamQuery, GovTeamQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GovTeamQuery, GovTeamQueryVariables>(
    GovTeamDocument,
    options,
  );
}
export type GovTeamQueryHookResult = ReturnType<typeof useGovTeamQuery>;
export type GovTeamLazyQueryHookResult = ReturnType<typeof useGovTeamLazyQuery>;
export type GovTeamSuspenseQueryHookResult = ReturnType<
  typeof useGovTeamSuspenseQuery
>;
export type GovTeamQueryResult = Apollo.QueryResult<
  GovTeamQuery,
  GovTeamQueryVariables
>;
export const GovDownloadSectionDocument = gql`
  query GovDownloadSection {
    governance {
      data {
        attributes {
          downloadSection {
            ...downloadSection
          }
        }
      }
    }
  }
  ${DownloadSectionFragmentDoc}
`;

/**
 * __useGovDownloadSectionQuery__
 *
 * To run a query within a React component, call `useGovDownloadSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovDownloadSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovDownloadSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGovDownloadSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GovDownloadSectionQuery,
    GovDownloadSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GovDownloadSectionQuery,
    GovDownloadSectionQueryVariables
  >(GovDownloadSectionDocument, options);
}
export function useGovDownloadSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GovDownloadSectionQuery,
    GovDownloadSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GovDownloadSectionQuery,
    GovDownloadSectionQueryVariables
  >(GovDownloadSectionDocument, options);
}
export function useGovDownloadSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GovDownloadSectionQuery,
        GovDownloadSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GovDownloadSectionQuery,
    GovDownloadSectionQueryVariables
  >(GovDownloadSectionDocument, options);
}
export type GovDownloadSectionQueryHookResult = ReturnType<
  typeof useGovDownloadSectionQuery
>;
export type GovDownloadSectionLazyQueryHookResult = ReturnType<
  typeof useGovDownloadSectionLazyQuery
>;
export type GovDownloadSectionSuspenseQueryHookResult = ReturnType<
  typeof useGovDownloadSectionSuspenseQuery
>;
export type GovDownloadSectionQueryResult = Apollo.QueryResult<
  GovDownloadSectionQuery,
  GovDownloadSectionQueryVariables
>;
export const GovProductImageBlockDocument = gql`
  query GovProductImageBlock {
    governance {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useGovProductImageBlockQuery__
 *
 * To run a query within a React component, call `useGovProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useGovProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GovProductImageBlockQuery,
    GovProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GovProductImageBlockQuery,
    GovProductImageBlockQueryVariables
  >(GovProductImageBlockDocument, options);
}
export function useGovProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GovProductImageBlockQuery,
    GovProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GovProductImageBlockQuery,
    GovProductImageBlockQueryVariables
  >(GovProductImageBlockDocument, options);
}
export function useGovProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GovProductImageBlockQuery,
        GovProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GovProductImageBlockQuery,
    GovProductImageBlockQueryVariables
  >(GovProductImageBlockDocument, options);
}
export type GovProductImageBlockQueryHookResult = ReturnType<
  typeof useGovProductImageBlockQuery
>;
export type GovProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useGovProductImageBlockLazyQuery
>;
export type GovProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useGovProductImageBlockSuspenseQuery
>;
export type GovProductImageBlockQueryResult = Apollo.QueryResult<
  GovProductImageBlockQuery,
  GovProductImageBlockQueryVariables
>;
export const GovSeoDocument = gql`
  query GovSeo {
    governance {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useGovSeoQuery__
 *
 * To run a query within a React component, call `useGovSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGovSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<GovSeoQuery, GovSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GovSeoQuery, GovSeoQueryVariables>(
    GovSeoDocument,
    options,
  );
}
export function useGovSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GovSeoQuery, GovSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GovSeoQuery, GovSeoQueryVariables>(
    GovSeoDocument,
    options,
  );
}
export function useGovSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GovSeoQuery, GovSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GovSeoQuery, GovSeoQueryVariables>(
    GovSeoDocument,
    options,
  );
}
export type GovSeoQueryHookResult = ReturnType<typeof useGovSeoQuery>;
export type GovSeoLazyQueryHookResult = ReturnType<typeof useGovSeoLazyQuery>;
export type GovSeoSuspenseQueryHookResult = ReturnType<
  typeof useGovSeoSuspenseQuery
>;
export type GovSeoQueryResult = Apollo.QueryResult<
  GovSeoQuery,
  GovSeoQueryVariables
>;
export const HomeHeroDocument = gql`
  query HomeHero {
    home {
      data {
        attributes {
          hero {
            ...hero
          }
        }
      }
    }
  }
  ${HeroFragmentDoc}
`;

/**
 * __useHomeHeroQuery__
 *
 * To run a query within a React component, call `useHomeHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeHeroQuery, HomeHeroQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export function useHomeHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeHeroQuery,
    HomeHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export function useHomeHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HomeHeroQuery, HomeHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export type HomeHeroQueryHookResult = ReturnType<typeof useHomeHeroQuery>;
export type HomeHeroLazyQueryHookResult = ReturnType<
  typeof useHomeHeroLazyQuery
>;
export type HomeHeroSuspenseQueryHookResult = ReturnType<
  typeof useHomeHeroSuspenseQuery
>;
export type HomeHeroQueryResult = Apollo.QueryResult<
  HomeHeroQuery,
  HomeHeroQueryVariables
>;
export const HomeHeadlineWithImageDocument = gql`
  query HomeHeadlineWithImage {
    home {
      data {
        attributes {
          headlineWithImageSection {
            ...headlineWithImageSection
          }
        }
      }
    }
  }
  ${HeadlineWithImageSectionFragmentDoc}
`;

/**
 * __useHomeHeadlineWithImageQuery__
 *
 * To run a query within a React component, call `useHomeHeadlineWithImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeHeadlineWithImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeHeadlineWithImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeHeadlineWithImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeHeadlineWithImageQuery,
    HomeHeadlineWithImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomeHeadlineWithImageQuery,
    HomeHeadlineWithImageQueryVariables
  >(HomeHeadlineWithImageDocument, options);
}
export function useHomeHeadlineWithImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeHeadlineWithImageQuery,
    HomeHeadlineWithImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeHeadlineWithImageQuery,
    HomeHeadlineWithImageQueryVariables
  >(HomeHeadlineWithImageDocument, options);
}
export function useHomeHeadlineWithImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeHeadlineWithImageQuery,
        HomeHeadlineWithImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeHeadlineWithImageQuery,
    HomeHeadlineWithImageQueryVariables
  >(HomeHeadlineWithImageDocument, options);
}
export type HomeHeadlineWithImageQueryHookResult = ReturnType<
  typeof useHomeHeadlineWithImageQuery
>;
export type HomeHeadlineWithImageLazyQueryHookResult = ReturnType<
  typeof useHomeHeadlineWithImageLazyQuery
>;
export type HomeHeadlineWithImageSuspenseQueryHookResult = ReturnType<
  typeof useHomeHeadlineWithImageSuspenseQuery
>;
export type HomeHeadlineWithImageQueryResult = Apollo.QueryResult<
  HomeHeadlineWithImageQuery,
  HomeHeadlineWithImageQueryVariables
>;
export const HomeTextBlockDocument = gql`
  query HomeTextBlock {
    home {
      data {
        attributes {
          textBlock {
            ...textBlock
          }
        }
      }
    }
  }
  ${TextBlockFragmentDoc}
`;

/**
 * __useHomeTextBlockQuery__
 *
 * To run a query within a React component, call `useHomeTextBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeTextBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeTextBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeTextBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeTextBlockQuery,
    HomeTextBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeTextBlockQuery, HomeTextBlockQueryVariables>(
    HomeTextBlockDocument,
    options,
  );
}
export function useHomeTextBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeTextBlockQuery,
    HomeTextBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeTextBlockQuery, HomeTextBlockQueryVariables>(
    HomeTextBlockDocument,
    options,
  );
}
export function useHomeTextBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeTextBlockQuery,
        HomeTextBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeTextBlockQuery,
    HomeTextBlockQueryVariables
  >(HomeTextBlockDocument, options);
}
export type HomeTextBlockQueryHookResult = ReturnType<
  typeof useHomeTextBlockQuery
>;
export type HomeTextBlockLazyQueryHookResult = ReturnType<
  typeof useHomeTextBlockLazyQuery
>;
export type HomeTextBlockSuspenseQueryHookResult = ReturnType<
  typeof useHomeTextBlockSuspenseQuery
>;
export type HomeTextBlockQueryResult = Apollo.QueryResult<
  HomeTextBlockQuery,
  HomeTextBlockQueryVariables
>;
export const HomeSliderImageSectionDocument = gql`
  query HomeSliderImageSection {
    home {
      data {
        attributes {
          sliderImageSection {
            ...history
          }
        }
      }
    }
  }
  ${HistoryFragmentDoc}
`;

/**
 * __useHomeSliderImageSectionQuery__
 *
 * To run a query within a React component, call `useHomeSliderImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSliderImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSliderImageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeSliderImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeSliderImageSectionQuery,
    HomeSliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomeSliderImageSectionQuery,
    HomeSliderImageSectionQueryVariables
  >(HomeSliderImageSectionDocument, options);
}
export function useHomeSliderImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeSliderImageSectionQuery,
    HomeSliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeSliderImageSectionQuery,
    HomeSliderImageSectionQueryVariables
  >(HomeSliderImageSectionDocument, options);
}
export function useHomeSliderImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeSliderImageSectionQuery,
        HomeSliderImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeSliderImageSectionQuery,
    HomeSliderImageSectionQueryVariables
  >(HomeSliderImageSectionDocument, options);
}
export type HomeSliderImageSectionQueryHookResult = ReturnType<
  typeof useHomeSliderImageSectionQuery
>;
export type HomeSliderImageSectionLazyQueryHookResult = ReturnType<
  typeof useHomeSliderImageSectionLazyQuery
>;
export type HomeSliderImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useHomeSliderImageSectionSuspenseQuery
>;
export type HomeSliderImageSectionQueryResult = Apollo.QueryResult<
  HomeSliderImageSectionQuery,
  HomeSliderImageSectionQueryVariables
>;
export const HomeValuesDocument = gql`
  query HomeValues {
    home {
      data {
        attributes {
          values {
            ...values
          }
        }
      }
    }
  }
  ${ValuesFragmentDoc}
`;

/**
 * __useHomeValuesQuery__
 *
 * To run a query within a React component, call `useHomeValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeValuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeValuesQuery,
    HomeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeValuesQuery, HomeValuesQueryVariables>(
    HomeValuesDocument,
    options,
  );
}
export function useHomeValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeValuesQuery,
    HomeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeValuesQuery, HomeValuesQueryVariables>(
    HomeValuesDocument,
    options,
  );
}
export function useHomeValuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeValuesQuery,
        HomeValuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeValuesQuery, HomeValuesQueryVariables>(
    HomeValuesDocument,
    options,
  );
}
export type HomeValuesQueryHookResult = ReturnType<typeof useHomeValuesQuery>;
export type HomeValuesLazyQueryHookResult = ReturnType<
  typeof useHomeValuesLazyQuery
>;
export type HomeValuesSuspenseQueryHookResult = ReturnType<
  typeof useHomeValuesSuspenseQuery
>;
export type HomeValuesQueryResult = Apollo.QueryResult<
  HomeValuesQuery,
  HomeValuesQueryVariables
>;
export const HomeMapSectionDocument = gql`
  query HomeMapSection {
    home {
      data {
        attributes {
          mapSection {
            ...mapSection
          }
        }
      }
    }
  }
  ${MapSectionFragmentDoc}
`;

/**
 * __useHomeMapSectionQuery__
 *
 * To run a query within a React component, call `useHomeMapSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeMapSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeMapSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeMapSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeMapSectionQuery,
    HomeMapSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeMapSectionQuery, HomeMapSectionQueryVariables>(
    HomeMapSectionDocument,
    options,
  );
}
export function useHomeMapSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeMapSectionQuery,
    HomeMapSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeMapSectionQuery, HomeMapSectionQueryVariables>(
    HomeMapSectionDocument,
    options,
  );
}
export function useHomeMapSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeMapSectionQuery,
        HomeMapSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeMapSectionQuery,
    HomeMapSectionQueryVariables
  >(HomeMapSectionDocument, options);
}
export type HomeMapSectionQueryHookResult = ReturnType<
  typeof useHomeMapSectionQuery
>;
export type HomeMapSectionLazyQueryHookResult = ReturnType<
  typeof useHomeMapSectionLazyQuery
>;
export type HomeMapSectionSuspenseQueryHookResult = ReturnType<
  typeof useHomeMapSectionSuspenseQuery
>;
export type HomeMapSectionQueryResult = Apollo.QueryResult<
  HomeMapSectionQuery,
  HomeMapSectionQueryVariables
>;
export const HomeLargeImageSectionDocument = gql`
  query HomeLargeImageSection {
    home {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useHomeLargeImageSectionQuery__
 *
 * To run a query within a React component, call `useHomeLargeImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeLargeImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeLargeImageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeLargeImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeLargeImageSectionQuery,
    HomeLargeImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomeLargeImageSectionQuery,
    HomeLargeImageSectionQueryVariables
  >(HomeLargeImageSectionDocument, options);
}
export function useHomeLargeImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeLargeImageSectionQuery,
    HomeLargeImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeLargeImageSectionQuery,
    HomeLargeImageSectionQueryVariables
  >(HomeLargeImageSectionDocument, options);
}
export function useHomeLargeImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeLargeImageSectionQuery,
        HomeLargeImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeLargeImageSectionQuery,
    HomeLargeImageSectionQueryVariables
  >(HomeLargeImageSectionDocument, options);
}
export type HomeLargeImageSectionQueryHookResult = ReturnType<
  typeof useHomeLargeImageSectionQuery
>;
export type HomeLargeImageSectionLazyQueryHookResult = ReturnType<
  typeof useHomeLargeImageSectionLazyQuery
>;
export type HomeLargeImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useHomeLargeImageSectionSuspenseQuery
>;
export type HomeLargeImageSectionQueryResult = Apollo.QueryResult<
  HomeLargeImageSectionQuery,
  HomeLargeImageSectionQueryVariables
>;
export const HomeNewsroomDocument = gql`
  query HomeNewsroom {
    home {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useHomeNewsroomQuery__
 *
 * To run a query within a React component, call `useHomeNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeNewsroomQuery,
    HomeNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeNewsroomQuery, HomeNewsroomQueryVariables>(
    HomeNewsroomDocument,
    options,
  );
}
export function useHomeNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeNewsroomQuery,
    HomeNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeNewsroomQuery, HomeNewsroomQueryVariables>(
    HomeNewsroomDocument,
    options,
  );
}
export function useHomeNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeNewsroomQuery,
        HomeNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeNewsroomQuery, HomeNewsroomQueryVariables>(
    HomeNewsroomDocument,
    options,
  );
}
export type HomeNewsroomQueryHookResult = ReturnType<
  typeof useHomeNewsroomQuery
>;
export type HomeNewsroomLazyQueryHookResult = ReturnType<
  typeof useHomeNewsroomLazyQuery
>;
export type HomeNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useHomeNewsroomSuspenseQuery
>;
export type HomeNewsroomQueryResult = Apollo.QueryResult<
  HomeNewsroomQuery,
  HomeNewsroomQueryVariables
>;
export const HomeSeoDocument = gql`
  query HomeSeo {
    home {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useHomeSeoQuery__
 *
 * To run a query within a React component, call `useHomeSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export type HomeSeoQueryHookResult = ReturnType<typeof useHomeSeoQuery>;
export type HomeSeoLazyQueryHookResult = ReturnType<typeof useHomeSeoLazyQuery>;
export type HomeSeoSuspenseQueryHookResult = ReturnType<
  typeof useHomeSeoSuspenseQuery
>;
export type HomeSeoQueryResult = Apollo.QueryResult<
  HomeSeoQuery,
  HomeSeoQueryVariables
>;
export const NavigationDocument = gql`
  query Navigation {
    navigation {
      data {
        attributes {
          closedMenuLinks {
            ...navLink
          }
          openMenuColumns {
            ...openMenuColumns
          }
          companyContactInfo {
            name
            address
          }
        }
      }
    }
  }
  ${NavLinkFragmentDoc}
  ${OpenMenuColumnsFragmentDoc}
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NavigationQuery,
        NavigationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<
  typeof useNavigationLazyQuery
>;
export type NavigationSuspenseQueryHookResult = ReturnType<
  typeof useNavigationSuspenseQuery
>;
export type NavigationQueryResult = Apollo.QueryResult<
  NavigationQuery,
  NavigationQueryVariables
>;
export const PrivacyPolicyDocument = gql`
  query PrivacyPolicy {
    privacyPolicy {
      data {
        attributes {
          policies {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivacyPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PrivacyPolicyQuery,
        PrivacyPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >(PrivacyPolicyDocument, options);
}
export type PrivacyPolicyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyQuery
>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyLazyQuery
>;
export type PrivacyPolicySuspenseQueryHookResult = ReturnType<
  typeof usePrivacyPolicySuspenseQuery
>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<
  PrivacyPolicyQuery,
  PrivacyPolicyQueryVariables
>;
export const CookiePolicyDocument = gql`
  query CookiePolicy {
    cookiePolicy {
      data {
        attributes {
          cookiePolicy {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useCookiePolicyQuery__
 *
 * To run a query within a React component, call `useCookiePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiePolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiePolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiePolicyQuery,
        CookiePolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export type CookiePolicyQueryHookResult = ReturnType<
  typeof useCookiePolicyQuery
>;
export type CookiePolicyLazyQueryHookResult = ReturnType<
  typeof useCookiePolicyLazyQuery
>;
export type CookiePolicySuspenseQueryHookResult = ReturnType<
  typeof useCookiePolicySuspenseQuery
>;
export type CookiePolicyQueryResult = Apollo.QueryResult<
  CookiePolicyQuery,
  CookiePolicyQueryVariables
>;
export const LegalNoticeDocument = gql`
  query LegalNotice {
    legalNotice {
      data {
        attributes {
          legalNotice {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useLegalNoticeQuery__
 *
 * To run a query within a React component, call `useLegalNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalNoticeQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegalNoticeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LegalNoticeQuery,
    LegalNoticeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export function useLegalNoticeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegalNoticeQuery,
    LegalNoticeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export function useLegalNoticeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LegalNoticeQuery,
        LegalNoticeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export type LegalNoticeQueryHookResult = ReturnType<typeof useLegalNoticeQuery>;
export type LegalNoticeLazyQueryHookResult = ReturnType<
  typeof useLegalNoticeLazyQuery
>;
export type LegalNoticeSuspenseQueryHookResult = ReturnType<
  typeof useLegalNoticeSuspenseQuery
>;
export type LegalNoticeQueryResult = Apollo.QueryResult<
  LegalNoticeQuery,
  LegalNoticeQueryVariables
>;
export const WhistleblowingPolicyDocument = gql`
  query WhistleblowingPolicy {
    whistleblowingPolicy {
      data {
        attributes {
          policies {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useWhistleblowingPolicyQuery__
 *
 * To run a query within a React component, call `useWhistleblowingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhistleblowingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhistleblowingPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhistleblowingPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WhistleblowingPolicyQuery,
        WhistleblowingPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export type WhistleblowingPolicyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyQuery
>;
export type WhistleblowingPolicyLazyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyLazyQuery
>;
export type WhistleblowingPolicySuspenseQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicySuspenseQuery
>;
export type WhistleblowingPolicyQueryResult = Apollo.QueryResult<
  WhistleblowingPolicyQuery,
  WhistleblowingPolicyQueryVariables
>;
export const CookiesSettingsDocument = gql`
  query CookiesSettings {
    cookieSetting {
      data {
        id
        attributes {
          actionButtonLabel
          necessaryCookiesHeading
          necessaryCookiesTable {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          optionalCookiesHeading
          optionalCookiesTale {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          title
          redirectButton {
            label
            path
          }
        }
      }
    }
  }
`;

/**
 * __useCookiesSettingsQuery__
 *
 * To run a query within a React component, call `useCookiesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiesSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesSettingsQuery, CookiesSettingsQueryVariables>(
    CookiesSettingsDocument,
    options,
  );
}
export function useCookiesSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export function useCookiesSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesSettingsQuery,
        CookiesSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export type CookiesSettingsQueryHookResult = ReturnType<
  typeof useCookiesSettingsQuery
>;
export type CookiesSettingsLazyQueryHookResult = ReturnType<
  typeof useCookiesSettingsLazyQuery
>;
export type CookiesSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCookiesSettingsSuspenseQuery
>;
export type CookiesSettingsQueryResult = Apollo.QueryResult<
  CookiesSettingsQuery,
  CookiesSettingsQueryVariables
>;
export const CookiesBarDocument = gql`
  query CookiesBar {
    cookiesBanner {
      data {
        id
        attributes {
          heading
          editButtonLabel
          declineButtonLabel
          acceptButtonLabel
          text
          extraLinks {
            ...button
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
`;

/**
 * __useCookiesBarQuery__
 *
 * To run a query within a React component, call `useCookiesBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesBarQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiesBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesBarQuery,
        CookiesBarQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export type CookiesBarQueryHookResult = ReturnType<typeof useCookiesBarQuery>;
export type CookiesBarLazyQueryHookResult = ReturnType<
  typeof useCookiesBarLazyQuery
>;
export type CookiesBarSuspenseQueryHookResult = ReturnType<
  typeof useCookiesBarSuspenseQuery
>;
export type CookiesBarQueryResult = Apollo.QueryResult<
  CookiesBarQuery,
  CookiesBarQueryVariables
>;
