import {
  LargeImageBackgroundType,
  LargeImageSection,
  LargeImageSectionProps,
  MapSection,
  MapSectionProps,
  SliderImageSection,
  SliderImageSectionProps,
  TextBlock,
  TextBlockType,
  useCaseType,
  ValuesSection,
  ValuesSectionProps,
} from '@rimactechnology/rimac-ui-lib';

import {
  FooterQuery,
  HeadlineWithImageSectionFragment,
  HeroFragment,
  HistoryFragment,
  LargeImageSectionFragment,
  MapSectionFragment,
  NavigationQuery,
  NewsroomFragment,
  SeoFragment,
  TextBlockFragment,
  ValuesFragment,
} from '@/api';
import { Layout } from '@/components/layout/Layout';
import NewsroomSection from '@/components/newsroom/NewsroomSection';
import { Seo } from '@/components/Seo';
import HeadlineWithImage from '@/components/shared/HeadlineWithImage';
import { Hero } from '@/components/shared/Hero';
import getCdlPublicId from '@/hooks/useCdlPublicId';

type Props = {
  seo?: SeoFragment | null;
  hero?: HeroFragment | null;
  headlineWithImageSection?: HeadlineWithImageSectionFragment | null;
  textBlock?: TextBlockFragment | null;
  sliderImageSection?: HistoryFragment | null;
  values?: ValuesFragment | null;
  mapSection?: MapSectionFragment | null;
  largeImageSection?: LargeImageSectionFragment | null;
  newsroom?: NewsroomFragment | null;
  header: NavigationQuery;
  footer: FooterQuery;
};

export const Homepage = ({
  seo,
  hero,
  headlineWithImageSection,
  textBlock,
  sliderImageSection,
  values,
  mapSection,
  largeImageSection,
  newsroom,
  header,
  footer,
}: Props) => {
  const textBlockProps: TextBlockType = {
    useCase: 'technology',
    anchor: textBlock?.anchor || undefined,
    heading: {
      title: textBlock?.title || '',
      subtitle: textBlock?.subtitle || '',
      description: textBlock?.paragraph || '',
    },
    sections: (textBlock?.textSection || []).map((section) => ({
      title: section?.title || '',
      icon: getCdlPublicId(section?.icon_svg?.data?.attributes?.url || ''),
      richText: section?.text,
    })),
    image: textBlock?.image?.img_desktop?.data?.attributes?.url
      ? {
          imgSrc: getCdlPublicId(
            textBlock?.image?.img_desktop?.data?.attributes?.url || '',
          ),
          imgSrcMobile: getCdlPublicId(
            textBlock?.image?.img_mobile?.data?.attributes?.url || undefined,
          ),
          alt: textBlock?.image?.alt || '',
          blurHash:
            textBlock?.image?.img_desktop?.data?.attributes?.blurhash ||
            'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
        }
      : undefined,
  };

  const sliderImageSectionProps: SliderImageSectionProps = {
    title: sliderImageSection?.title || '',
    anchor: sliderImageSection?.anchor || undefined,
    useCase: 'technology' as useCaseType,
    button: undefined,
    cards: (sliderImageSection?.item || []).map((card) => ({
      useCase: 'technology' as useCaseType,
      title: card?.title || '',
      description: card?.description || undefined,
      tagName: card?.year || '',
      link: card?.linkPath || undefined,
      linkLabel: card?.linkText || '',
      linkDescription: `Go to: ${card?.linkPath || ''}`,
      image: {
        imgSrc: getCdlPublicId(
          card?.image_desktop?.data?.attributes?.url || '',
        ),
        imgSrcMobile: getCdlPublicId(
          card?.img_mobile?.data?.attributes?.url || undefined,
        ),
        alt: card?.alt || '',
        blurHash: card?.image_desktop?.data?.attributes?.blurhash || undefined,
      },
    })),
  };

  const valuesSectionProps: ValuesSectionProps = {
    title: values?.title || '',
    anchor: values?.anchor || undefined,
    cards: (values?.iconCard || []).map((card, index) => ({
      index: index || 1,
      title: card?.title || '',
      icon: getCdlPublicId(card?.icon_svg?.data?.attributes?.url || ''),
      text: card?.text || '',
    })),
  };

  const mapSectionProps: MapSectionProps = {
    anchor: mapSection?.anchor || undefined,
    parentIndex: 1,
    title: mapSection?.title || '',
    mapItems: (mapSection?.mapItems || []).map((item) => ({
      tabOption: {
        title: item?.tabTitle || '',
        bgColor: `#${item?.tagColor?.split('_').pop()}`,
        selectedTextColor: item?.blackText ? 'black' : 'white',
      },
      mapImages: {
        leftMap: {
          title: item?.leftMapTitle || '',
          mapImage: {
            imgSrc: getCdlPublicId(
              item?.leftMapImage?.data?.attributes?.url || '',
            ),
            imgSrcMobile: getCdlPublicId(
              item?.leftMapImageMobile?.data?.attributes?.url || '',
            ),
            alt: item?.leftMapTitle || '',
            blurHash:
              item?.leftMapImage?.data?.attributes?.blurhash ||
              'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
          },
        },
        rightMap: {
          title: item?.rightMapTitle || '',
          mapImage: {
            imgSrc: getCdlPublicId(
              item?.rightMapImage?.data?.attributes?.url || '',
            ),
            imgSrcMobile: getCdlPublicId(
              item?.rightMapImageMobile?.data?.attributes?.url || '',
            ),
            alt: item?.rightMapTitle || '',
            blurHash:
              item?.rightMapImage?.data?.attributes?.blurhash ||
              'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
          },
        },
      },
    })),
  };

  const largeImageSectionProps: LargeImageSectionProps = {
    anchor: largeImageSection?.anchor || undefined,
    image: {
      imgSrc: getCdlPublicId(
        largeImageSection?.image?.img_desktop.data?.attributes?.url || '',
      ),
      imgSrcMobile: getCdlPublicId(
        largeImageSection?.image?.img_mobile?.data?.attributes?.url ||
          undefined,
      ),
      alt: largeImageSection?.image?.alt || '',
      blurHash:
        largeImageSection?.image?.img_desktop.data?.attributes?.blurhash ||
        'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
    },
    bgColor: 'light-blue' as LargeImageBackgroundType,
    title: largeImageSection?.title || '',
    description: largeImageSection?.description || '',
    button: {
      label: largeImageSection?.button?.label || '',
      path: largeImageSection?.button?.path || '',
      openInNewTab: !!largeImageSection?.button?.openInNewTab,
      useCase: 'group' as useCaseType,
    },
  };

  return (
    <Layout headerData={header} footerData={footer}>
      <Seo seo={seo} />
      <Hero hero={hero} titleMaxWidth={870} />
      <HeadlineWithImage headlineWithImageSection={headlineWithImageSection} />
      <TextBlock {...textBlockProps} />
      <SliderImageSection hasLargeTitle={false} {...sliderImageSectionProps} />
      <ValuesSection {...valuesSectionProps} />
      <MapSection {...mapSectionProps} />
      <LargeImageSection
        {...largeImageSectionProps}
        textCenter
        descriptionMaxWidth={680}
      />
      <NewsroomSection strapiData={newsroom} />
    </Layout>
  );
};
